import type { components } from 'schema'

export enum NotificationTypeEnum {
  All = 'ALL',
  Project = 'PROJECT',
  Legal = 'LEGAL',
  Billing = 'BILLING',
  Operational = 'OPERATIONAL'
}

export interface INotificationsCountData {
  [NotificationTypeEnum.All]: number
  [NotificationTypeEnum.Project]: number
  [NotificationTypeEnum.Legal]: number
  [NotificationTypeEnum.Billing]: number
  [NotificationTypeEnum.Operational]: number
}
export interface INotificationsStoreTypes {
  currentPage: number
  isPagination: boolean
  notifications: components['schemas']['GetNotification'][]
  notificationCount: INotificationsCountData
}

import type { MaskInputOptions } from 'maska'
import { MaskInput } from 'maska'
import type { DirectiveBinding } from 'vue'

export const maskDirective = {
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	mounted (
		el: HTMLElement,
		binding: DirectiveBinding<MaskInputOptions>
	) {
		const options = binding.value

		const inputElement = el.querySelector('input')

		if (!inputElement) {
			return
		}
		// eslint-disable-next-line no-new
		new MaskInput(inputElement, options)
	}
}

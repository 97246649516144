import type { App } from 'vue'
import type { Router } from 'vue-router'
import { createRouter as createVueRouter, createWebHistory } from 'vue-router'

import {
	authMiddleware,
	loadLayoutMiddleware
} from '~/middleware'
import Login from '~/pages/login.vue'
import {
	getAccountRoutes,
	getAngebotFormRoute,
	getChecklistItemRoute,
	getChecklistRoute,
	getClientRoutes,
	getCompanyManagementRoutes,
	getDemoRoutes,
	getDocumentCheckingRoute,
	getE9FormRoute,
	getEmployeeRouter,
	getEzeReportFormsRoute,
	getHomeRoutes,
	getIntermediateProtectionRoute,
	getMemberRouter,
	getMembersAndTeamsRoutes,
	getNotificationsRoutes,
	getProjectRoutes,
	getReportFormsRoutes,
	getSingleProjectCertificatePdfViewerRoute,
	getSingleProjectInvoicePdfViewerRoute,
	getSingleProjectOfferPdfViewerRoute,
	getSingleProjectProductPage,
	getSingleProjectReportPdfViewerRoute,
	getSingleProjectRoutes,
	getSystemControllerRoute,
	getTeamRouter,
	getTransformerRoute,
	getUserRouter
} from '~/router'
import { RoutesNamesEnum } from '~/router/modules/router/routerTypes'

export function createRouter (app?: App): Router {
	const router = createVueRouter({
		routes: [
			getHomeRoutes(app),
			getDemoRoutes(),
			getAccountRoutes(),
			getProjectRoutes(app),
			getUserRouter(app),
			getSingleProjectRoutes(app),
			getDocumentCheckingRoute(app),
			getCompanyManagementRoutes(app),
			getClientRoutes(app),
			getEmployeeRouter(app),
			getMemberRouter(app),
			getTeamRouter(app),
			getMembersAndTeamsRoutes(app),
			getNotificationsRoutes(app),
			getReportFormsRoutes(app),
			getE9FormRoute(app),
			getSystemControllerRoute(app),
			getIntermediateProtectionRoute(app),
			getTransformerRoute(app),
			getEzeReportFormsRoute(app),
			getChecklistRoute(app),
			getChecklistItemRoute(app),
			getSingleProjectInvoicePdfViewerRoute(app),
			getSingleProjectOfferPdfViewerRoute(app),
			getSingleProjectCertificatePdfViewerRoute(app),
			getSingleProjectReportPdfViewerRoute(app),
			getAngebotFormRoute(app),
			getSingleProjectProductPage(app),
			{
				path: '/data-protection',
				name: RoutesNamesEnum.dataProtection,
				component: () => import('~/pages/data-protection.vue')
			},
			{
				path: '/login',
				name: RoutesNamesEnum.login,
				component: Login
			},
			{
				path: '/:pathMatch(.*)*',
				name: RoutesNamesEnum.notFound,
				component: () => import('~/pages/not-found.vue')
			}
		],
		history: createWebHistory()
	})

	router.beforeEach(authMiddleware)
	router.beforeEach(loadLayoutMiddleware)

	return router
}

import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { RoutesNamesEnum } from '~/router'

export const getDocumentCheckingRoute = (app?: App): RouteRecordRaw => ({
	path: '/project/:projectId/checklist/:checklistId/:itemType/:checklistItemId/',
	name: RoutesNamesEnum.documentChecking,
	component: () => import('~/pages/document-checking.vue'),
	beforeEnter: createAuthGuard(app as any)
})

import { defineStore } from 'pinia'

import type {
	IFetchApiEmployeeUserUpdate,
	IFetchApiGetEmployeeById,
	IFetchApiGetEmployeeList,
	IFetchApiGetEmployeeProjects,
	IFetchApiReassignEmployeeProjects
} from '~/api'
import {
	fetchApiEmployeeUserUpdate,
	fetchApiGetEmployeeById,
	fetchApiGetEmployeeList,
	fetchApiGetEmployeeProjects,
	fetchApiReassignEmployeeProjects
} from '~/api'
import type { IEmployeeStore } from '~/stores'

export const useEmployeeStore = defineStore('employee', {
	state: (): IEmployeeStore => ({
		projects: [],
		currentPage: 1,
		isPagination: true,
		employeeCurrent: {},
		employeeList: []
	}),
	getters: {
		currentEmployee (state: IEmployeeStore) {
			return (id: string | number) => state.employeeCurrent[id]
		}
	},
	actions: {
		resetEmployeeProjectList () {
			this.projects = []
			this.currentPage = 1
			this.isPagination = true
		},
		async fetchEmployeeById (
			payload: IFetchApiGetEmployeeById
		) {
			const response = await fetchApiGetEmployeeById(payload)

			if (response && response.data) {
				this.employeeCurrent[payload.id] = response.data
			}
		},
		async fetchApiGetEmployeeProjects (
			payload: IFetchApiGetEmployeeProjects
		) {
			const response = await fetchApiGetEmployeeProjects(payload)

			if (response) {
				this.currentPage = response.next ??
					(response.previous + 1) ??
					1

				this.projects?.push(...response.results)
				this.isPagination = !!response.next
			}
		},
		async fetchGetEmployeeList (
			payload: IFetchApiGetEmployeeList
		) {
			this.employeeList = await fetchApiGetEmployeeList(payload)
		},
		async reassignEmployeeProjects (
			payload: IFetchApiReassignEmployeeProjects
		) {
			return fetchApiReassignEmployeeProjects(payload)
		},
		async employeeUserUpdate (
			payload: IFetchApiEmployeeUserUpdate
		) {
			return fetchApiEmployeeUserUpdate(payload)
		}
	}
})

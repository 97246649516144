import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { TeamRoutesNamesEnum } from '.'

export const getTeamRouter = (app?: App): RouteRecordRaw => {
	return 		{
		path: '/team/:id',
		name: TeamRoutesNamesEnum.team,
		component: () => import('~/pages/team/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		redirect: { name: TeamRoutesNamesEnum.teamMembers },
		children: [
			{
				path: 'members',
				name: TeamRoutesNamesEnum.teamMembers,
				component: () => import('~/pages/team/members.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'projects',
				name: TeamRoutesNamesEnum.teamProjects,
				component: () => import('~/pages/team/projects.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}

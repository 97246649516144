import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

const pendingKey = STATE_STORE_KEYS.TeamApiInteractive

export const fetchApiGetTeamById = async (
	payload: {
		token: IFetchApiProps['token']
		id: number
	}
): Promise<
  components['schemas']['GetCompanyClientAdmin'] | undefined
	> => {
	if (!payload.token || !payload.id) return

	const url = `/user/team/${payload.id}`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		token: payload.token,
		pendingKey
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetTeamMembersList = async (
	payload: {
		token: IFetchApiProps['token']
		id: number
		currentPage: number
	}
): Promise<
	components['schemas']['PaginatedListClientsList'] | undefined
> => {
	if (!payload.token || !payload.id) return

	const response = await fetchApi({
		url: `/user/team/${payload.id}/member/list?page=${payload.currentPage}`,
		method: FETCH_API_METHODS.Get,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.MemberInteractive
	})

	if (response && response.data) {
		return response.data
	}
}

export const fetchPostAddTeamMembers = async (
	payload: {
		token: IFetchApiProps['token']
		members: number[] | undefined
		id: number | undefined
	}
) => {
	if (!payload.token && !payload.id && !payload.members?.length) return

	await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/user/team/${payload.id}/add-members`,
		token: payload.token,
		pendingKey,
		payload: {
			members: payload.members
		}
	})
}

export const fetchApiGetMemberList = async (
	payload: {
		token: IFetchApiProps['token']
		id: number | undefined
	}
) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/user/team/${payload.id}/members`,
		pendingKey,
		token: payload.token
	})
}

export const fetchApiGetMembersListByCompany = async (
	payload: {
		token: IFetchApiProps['token']
    params?: {
      page?: number
      search?: string
      return_all?: boolean
      is_blocked?: boolean
      role?: string
    }
	}
) => {
	if (!payload.token) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/user/member/list',
		pendingKey: STATE_STORE_KEYS.MemberInteractive,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetTeamMemberProjectsList = async (
	payload: {
		token: IFetchApiProps['token']
		id: number | undefined
    params?: {
      page?: number
      ordering?: string
      return_all?: boolean
    }
	}
) => {
	if (!payload.token && !payload.id) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/user/team/${payload.id}/project/list`,
		pendingKey: STATE_STORE_KEYS.TeamProjects,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response?.data
	}
}

export const fetchPostCreateTeam = async (
	payload: {
		token: IFetchApiProps['token']
		name: string
		members: number[] | undefined
	}
) => {
	if (!payload.token && !payload.name && !payload.members?.length) return

	await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/team',
		token: payload.token,
		pendingKey,
		payload: {
			name: payload.name,
			members: payload.members
		}
	})
}

export const fetchDeleteMemberById = async (
	payload: {
		token: IFetchApiProps['token']
		id: number
		members: number[]
	}
) => {
	if (!payload.token && !payload.id && !payload.members?.length) return

	await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/user/team/${payload.id}/remove-members`,
		token: payload.token,
		pendingKey,
		payload: {
			members: payload.members
		}
	})
}

export const fetchPatchEditTeamName = async (
	payload: {
		token: IFetchApiProps['token']
		id: number
		name: string
	}
) => {
	if (!payload.token && !payload.id && !payload.name) return

	await fetchApi({
		method: FETCH_API_METHODS.Patch,
		url: `/user/team/${payload.id}/update`,
		token: payload.token,
		pendingKey,
		payload: {
			name: payload.name
		}
	})
}

export const fetchDeleteTeam = async (
	payload: {
		token: IFetchApiProps['token']
		id: number | undefined
	}
) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/user/team/${payload.id}/delete`,
		token: payload.token,
		pendingKey
	})
}

export const fetchApiGetTeamsList = async (payload: {
  token: IFetchApiProps['token']
  params?: {
    page?: number
    search?: string
    return_all?: boolean
  }
}) => {
	if (!payload.token) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/user/team/list',
		pendingKey,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteMember = async (payload: {
  token: IFetchApiProps['token']
  id: number
}) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/user/${payload.id}/delete`,
		token: payload.token,
		pendingKey
	})
}

export const fetchPostReassignProjectToTeam = (
	payload: {
		token: IFetchApiProps['token']
		newTeamId?: number
    newEmployeeId?: number
		projects: number[]
	}
) => {
	if (!payload.token || !payload.projects?.length) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/team/project/re-assign',
		token: payload.token,
		pendingKey,
		payload: {
			new_employee: payload.newEmployeeId,
			new_team: payload.newTeamId,
			projects: payload.projects
		}
	})
}

export const fetchPostReassignProjectToMember = (
	payload: {
		token: IFetchApiProps['token']
		projects: number[]
    user: number
	}
) => {
	if (!payload.token && !payload.projects?.length) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/team/project/re-assign',
		token: payload.token,
		pendingKey,
		payload: {
			new_employee: payload.user,
			projects: payload.projects
		}
	})
}

export const fetchCancelInvitation = async (payload: {
  token: IFetchApiProps['token']
  id: number
}) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/user/${payload.id}/cancel-invite`,
		token: payload.token,
		pendingKey
	})
}

export const fetchInviteMembers = async (payload: {
  token: IFetchApiProps['token']
  emails: string[]
  teamId?: number
  allowCommercialInfoAccess: boolean
}) => {
	if (!payload.token && !payload.emails.length) return

	return await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/team/invite',
		token: payload.token,
		pendingKey,
		payload: {
			emails: payload.emails,
			can_see_commercial_info: payload.allowCommercialInfoAccess,
			teams: payload.teamId ? [payload.teamId] : []
		}
	})
}

export const fetchApiReInviteMember = (payload: {
  token: IFetchApiProps['token']
  email: string
  team?: number | null
  can_see_commercial_info?: boolean
}) => {
	if (!payload.token || !payload.email) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/re-invite',
		token: payload.token,
		pendingKey,
		payload: {
			email: payload.email,
			teams: payload.team ? [payload.team] : [],
			can_see_commercial_info: payload.can_see_commercial_info
		}
	})
}

export const fetchApiCreateTeam = async (payload: {
  token: IFetchApiProps['token']
  name: string
  members: number[]
}) => {
	if (!payload.token && !payload.name && !payload.members.length) return

	await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/user/team',
		token: payload.token,
		pendingKey,
		payload: {
			name: payload.name,
			members: payload.members
		}
	})
}

export const fetchApiDeleteTeam = async (payload: {
  token: IFetchApiProps['token']
  id: number
}) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/user/team/${payload.id}/delete`,
		token: payload.token,
		pendingKey
	})
}

export interface IFetchApiGetMemberProjects {
	token: IFetchApiProps['token']
	id: number
	params?: {
		search?: string
		page?: number
		ordering?: string
		return_all?: boolean
	}
}

export const fetchApiGetMemberProjects = async (
	payload: IFetchApiGetMemberProjects
) => {
	if (!payload.token || !payload.id) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/user/${payload.id}/project/list`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.MemberProjects,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiAssignMemberToProject = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  userIds: number[]
}) => {
	if (!payload.token && !payload.projectId && !payload.userIds.length) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${payload.projectId}/client-assign`,
		token: payload.token,
		pendingKey,
		payload: {
			client_employees: payload.userIds
		}
	})
}

export const fetchApiAssignTeamToProject = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  teamIds: number[]
}) => {
	if (!payload.token || !payload.projectId) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/user/team/project/${payload.projectId}/assign`,
		token: payload.token,
		pendingKey,
		payload: {
			teams: payload.teamIds
		}
	})
}

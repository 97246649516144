export enum ECP_COMPANY_INVOICES_TABLE_COLUMNS {
  DocumentName = 'Name des Dokuments',
  ProjectName = 'Name des Projekts',
  Managers = 'PMs',
  InvoiceType = 'Art der Rechnung',
  Service = 'Dienst',
  Price = 'Preiswert',
  Date = 'Datum und Uhrzeit',
  Status = 'Status',
  Actions = 'Aktionen'
}

export enum ECP_COMPANY_INVOICES_STATUS {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  Irrelevant = 'IRRELEVANT',
}

export enum ECP_COMPANY_INVOICES_TYPES {
  Prepayment = 'PREPAYMENT',
  MainPrepayment = 'MAIN_PREPAYMENT',
  AdditionalPrepayment = 'ADDITIONAL_PREPAYMENT',
}

export interface ICPCompanyInvoicesTableData {
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.DocumentName]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.ProjectName]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Managers]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.InvoiceType]: string | null
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Service]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Date]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Price]: string
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Status]: {
    name: string | null
    invoiceId: number
    projectId: number
  }
  [ECP_COMPANY_INVOICES_TABLE_COLUMNS.Actions]: {
    name?: string | null
    invoiceId: number
    projectId: number
  }
}

export interface ICPCompanyInvoicesTableProps {
  data: ICPCompanyInvoicesTableData[]
  loading?: boolean
}

import { toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useProjectCreationStore } from '~/stores'

export const addressInfoMiddleware = async (
	_: RouteLocationNormalized,
	__: RouteLocationNormalized,
	next: NavigationGuardNext
): Promise<void> => {
	const projectStore = useProjectCreationStore()
	const { applicantInformation } = storeToRefs(projectStore)
	const { applicant } = toRefs(applicantInformation.value)

	const isEmptyApplicant = Object.values(applicant.value).some((value) => !!value)

	if (!isEmptyApplicant) {
		next('project-creation')
	} else {
		next()
	}
}

<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

<template>
  <Component
    :is="route.meta.layoutComponent"
  >
    <slot />
  </Component>
</template>

<style lang="scss">
//
</style>

export enum ECP_TEAMS_TABLE_COLUMNS {
  Name = 'Name',
  Members = 'Mitglieder',
  LastActivity = 'Letzte Aktivität',
  Projects = 'Projekte',
  Actions = 'Aktionen'
}

export interface ICPTeamMember {
  id: number
  initials: string
  icon: string
}

export interface ICPTeamsTableRow {
  [ECP_TEAMS_TABLE_COLUMNS.Name]: string
  [ECP_TEAMS_TABLE_COLUMNS.Members]: ICPTeamMember[]
  [ECP_TEAMS_TABLE_COLUMNS.LastActivity]: string
  [ECP_TEAMS_TABLE_COLUMNS.Projects]: number | string
  [ECP_TEAMS_TABLE_COLUMNS.Actions]: number
}

export interface ICPTeamsTableProps {
  data: ICPTeamsTableRow[]
  loading?: boolean
}

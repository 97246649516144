<script setup lang="ts">
import { computed } from 'vue'

import type { ICPSvgIconTypes } from '~/components/base'

const props = withDefaults(
	defineProps<ICPSvgIconTypes>(),
	{
		size: 'md',
		prefix: 'icon',
		clickable: false
	}
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const symbolId = computed(() => `#${props.prefix}-${props.name}`)

const sizeComputed = computed(() => {
	switch (props.size) {
	case 'sm':
		return 16
	case 'md':
		return 24
	case 'lg':
		return 32
	default:
		return props.size
	}
})

const onClick = (): void => {
	emit('click')
}
</script>

<script lang="ts">
export default {
	inheritAttrs: false
}
</script>

<template>
  <svg
    :class="[
      'cp-svg-icon',
      {
         'cp-svg-icon--clickable':  clickable,
      },
      $attrs.class
    ]"
    :width="sizeComputed"
    :height="sizeComputed"
    aria-hidden="true"
    @click="onClick"
  >
    <use :href="symbolId" />
  </svg>
</template>

<style scoped lang="scss">
.cp-svg-icon {
  width: v-bind(sizeComputed);
  height: v-bind(sizeComputed);

  &--clickable {
    @apply
      cursor-pointer
    ;
  }
}
</style>

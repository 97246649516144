export enum CPChatType {
  Default = 'Default',
  Notes = 'Notes'
}

export interface ICPChatPropTypes {
  projectIds: string[]
  hasNotes?: boolean
  loadingNav?: boolean
  disabledNav?: boolean
  showNewMessageNav?: boolean
  enableProjectButton?: boolean
  disabled?: boolean
}

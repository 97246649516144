import { defineStore } from 'pinia'

import type {
	IFetchApiCertificateList,
	IFetchApiCertificateListByProject
} from '~/api'
import {
	fetchApiCertificateList,
	fetchApiCertificateListByProject
} from '~/api'
import type { ICertificateStoreType } from '~/stores'

export const useCertificateStore = defineStore('certificateStore', {
	state: (): ICertificateStoreType => ({
		pageCount: 0,
		currentPage: 1,
		isPagination: false,
		certificateList: [],
		certificateByProjectList: []
	}),
	actions: {
		resetCertificateState () {
			this.currentPage = 1
			this.certificateList = []
			this.isPagination = false
		},
		resetCertificateByProjectState () {
			this.certificateByProjectList = []
		},
		async fetchCertificateList (
			payload: IFetchApiCertificateList
		) {
			const response = await fetchApiCertificateList({
				token: payload.token,
				params: payload.params
			})

			if (response && response.data) {
				this.pageCount = response.data.count
				this.currentPage = response.data.next ??
					(response.data.previous + 1) ??
					1
				this.certificateList?.push(...response.data.results)
				this.isPagination = !!response.data.next
			}
		},
		async fetchCertificateListByProject (
			payload: IFetchApiCertificateListByProject
		) {
			const response = await fetchApiCertificateListByProject(payload)

			if (response && response.data) {
				this.certificateByProjectList = response.data
			}
		}
	}
})

import { defineStore } from 'pinia'

import { fetchApiDeleteOffer, fetchApiGetOffers } from '~/api'

import type { IOffersStoreProps } from './offersStoreTypes'

export const useOffersStore = defineStore('offers-store', {
	state (): IOffersStoreProps {
		return {
			offers: [],
			currentPage: 1,
			pageCount: 0,
			isPagination: false
		}
	},
	actions: {
		resetOfferList () {
			this.offers = []
			this.currentPage = 1
			this.pageCount = 0
			this.isPagination = false
		},
		async fetchOffers (payload: {
      token: string
      projectId: number
    }) {
			const response = await fetchApiGetOffers({
				...payload,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				this.offers.push(...response.results)
				this.pageCount = response.count
				this.currentPage = response.next ??
						(response.previous + 1) ??
						1
				this.isPagination = !!response.next
			}
		},
		async deleteOffer (payload: {
      token: string
      projectId: number
      offerId: number
    }) {
			await fetchApiDeleteOffer(payload)
			await this.fetchOffers({
				token: payload.token,
				projectId: payload.projectId
			})
		}
	}
})

import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiPostAdditionalEquipment {
  token: IFetchApiProps['token']
  projectId: number
  payload: {
    type: string
    manufacturer: string
  }
}

export interface IFetchApiUpdateTransformator {
  token: IFetchApiProps['token']
  projectId: number
  productId: number
  payload: components['schemas']['PatchedTransformatorUpdate']
}

export const fetchApiPostAdditionalEquipment = ({
	token,
	projectId,
	payload
}: IFetchApiPostAdditionalEquipment) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.AdditionalEquipment,
		url: `/project/${projectId}/additional-equipment`,
		payload
	})
}

export const fetchApiAdditionalEquipment = async (payload: {
  token: IFetchApiProps['token']
  projectId: number
  params?: {
    type?: string
    page?: number
  }
}) => {
	const response = await fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.AdditionalEquipment,
		url: `/project/${payload.projectId}/additional-equipment/list`,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteAdditionalProduct = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  productId: number
}) => {
	return fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.AdditionalEquipment,
		url: `/project/${payload.projectId}/additional-equipment/${payload.productId}/delete`
	})
}

export const fetchApiUpdateProduct = ({
	token,
	projectId,
	productId,
	payload
}: IFetchApiUpdateTransformator) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Patch,
		pendingKey: STATE_STORE_KEYS.AdditionalEquipment,
		url: `/project/${projectId}/additional-equipment/${productId}/update`,
		payload
	})
}

import type { App } from 'vue'
import type { Router } from 'vue-router'

import * as Sentry from '@sentry/vue'

export const initSentry = ({ app, router }: {app: App; router?: Router}) => {
	return Sentry.init({
		app,
		environment: import.meta.env.VITE_ENV_NAME,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration()
		],
		// Tracing
		tracesSampleRate: 1.0,
		// Set 'tracePropagationTargets' to control
		// for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	})
}

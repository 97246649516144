import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi, httpClient } from '~/api'
import type { IProjectCreationDefinitionPayload } from '~/stores'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiDeleteProjectPayload {
	token: IFetchApiProps['token']
  id: number | undefined
}

export const fetchApiDeleteProject = async (
	payload: IFetchApiDeleteProjectPayload
) => {
	if (!payload.token && !payload.id) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/project/${payload.id}/delete`,
		payload: {
			id: payload.id
		},
		pendingKey: STATE_STORE_KEYS.DeleteProject,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchApiGetProjectPayload {
	token: IFetchApiProps['token']
  id: number | undefined
}

export const fetchApiGetProject = async (
	payload: IFetchApiGetProjectPayload
): Promise<components['schemas']['GetProjectInfo'] | undefined> => {
	if (!payload.token && !payload.id) return

	const response = await fetchApi({
		url: `/project/${payload.id}`,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.UpdateProject,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchApiPatchUpdateProjectPayload {
	payload: components['schemas']['CreateProject']
	token: IFetchApiProps['token']
	pendingKey?: STATE_STORE_KEYS
	id: number
}

export const fetchApiPatchUpdateProject = async (
	payload: IFetchApiPatchUpdateProjectPayload
) => {
	if (!payload.token) return

	if (payload.id) {
		const response = await fetchApi({
			method: FETCH_API_METHODS.Patch,
			url: `/project/${payload.id}/update`,
			payload: {
				...payload.payload
			},
			pendingKey: payload.pendingKey || STATE_STORE_KEYS.UpdateProject,
			token: payload.token
		})

		if (response?.data) {
			return response
		}
	} else {
		// eslint-disable-next-line no-console
		console.error('Can\'t get project id')
	}
}

export const fetchApiPutUpdateProject = async (
	payload: IFetchApiPatchUpdateProjectPayload
) => {
	if (!payload.token) return

	if (payload.id) {
		const response = await fetchApi({
			method: FETCH_API_METHODS.Put,
			url: `/project/${payload.id}/update`,
			payload: payload.payload,
			pendingKey: payload.pendingKey || STATE_STORE_KEYS.UpdateProject,
			token: payload.token
		})

		if (response?.data) {
			return response
		}
	} else {
		// eslint-disable-next-line no-console
		console.error('Can\'t get project id')
	}
}

export const fetchApiGetFileFromUrl = async (url: string) => {
	const getFileFromUrl = async () => {
		try {
			const response = await httpClient.get(
				url,
				{ responseType: 'blob' }
			)
			const blob = response.data
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			return new Promise((resolve, reject) => {
				reader.onloadend = () => {
					if (reader.result) {
						resolve(
							{
								size: blob.size,
								base64: reader.result.toString()
							})
					} else {
						reject(new Error('Failed to read file data.'))
					}
				}
				reader.onerror = () => {
					reject(new Error('Error reading file data.'))
				}
			})
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error loading file from URL:', error)
			throw error
		}
	}

	try {
		const response = await getFileFromUrl()
		const parts = new URL(url).pathname.split('/')
		const fileName = parts[parts.length - 1]
		const { size, base64 } = response as {
					size: number
					base64: string
				}
		return {
			name: fileName,
			size,
			base64
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching file data:', error)
		throw error
	}
}

export const fetchApiPostCreateProject = async (
	payload: {
		token: IFetchApiProps['token']
		payload: IProjectCreationDefinitionPayload
		pendingKey?: STATE_STORE_KEYS
	}
) => {
	if (!payload.token) return
	const response = await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: '/project/',
		payload: { ...payload.payload },
		pendingKey: payload.pendingKey || STATE_STORE_KEYS.CreationProject,
		token: payload.token
	})

	if (response?.data) {
		return response
	}
}

export interface IFetchApiProjectsIds {
	token: IFetchApiProps['token']
	params?: {
		page?: number
		offset?: number
		limit?: number
	}
}

export const fetchApiProjectsIds = async (
	payload: IFetchApiProjectsIds
) => {
	const response = await fetchApi({
		url: '/project/ids/list',
		method: FETCH_API_METHODS.Get,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.ProjectsIds,
		params: payload.params
	})

	if (response?.data) {
		return response
	}
}

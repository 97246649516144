export const fileToBase64 = (file: File| string) => new Promise<string | null>(
	(resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file instanceof File
			? file
			: new File([file], 'temp')
		)

		reader.onload = () => {
			const base64String = reader.result

			if (typeof base64String === 'string') {
				const withoutPrefix = base64String.split('base64,')[1]
				resolve(withoutPrefix)
			} else {
				resolve(null)
			}
		}

		reader.onerror = () => reject(new Error('Error reading the file.'))
	}
)

import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { UserRoutesNamesEnum } from '~/router/modules/user/userTypes'

export const getUserRouter = (app?: App): RouteRecordRaw => {
	return 		{
		path: '/user',
		name: UserRoutesNamesEnum.user,
		component: () => import('~/pages/user/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		children: [
			{
				path: 'billing-address',
				name: UserRoutesNamesEnum.billingAddress,
				component: () => import('~/pages/user/billing-address.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'personal-information',
				name: UserRoutesNamesEnum.personalInformation,
				component: () => import('~/pages/user/personal-information.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'change-password',
				name: UserRoutesNamesEnum.changePassword,
				component: () => import('~/pages/user/change-password.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}

import { isEmpty } from 'lodash'
import { defineStore } from 'pinia'
import type { components } from 'schema'

import { FETCH_API_METHODS, fetchApi, httpClient } from '~/api'
import type { IUserStoreType } from '~/stores'
import { STATE_STORE_KEYS } from '~/types'

export const useUserStore = defineStore('userStore', {
	state: (): IUserStoreType => ({
		user: {},
		internalInvitedStatus: false,
		userList: [],
		isShowLogoutDialog: false,
		userIconUrl: ''
	}),
	getters: {
		checkInvitedUser (state: IUserStoreType) {
			return state.user.is_invited
		},
		getUserRole (state: IUserStoreType) {
			return state.user.role?.keyword
		}
	},
	actions: {
		updateInternalInvitedStatus (payload: boolean) {
			this.internalInvitedStatus = payload
		},
		async fetchCurrentUser (token: string = '') {
			if (!token) {
				return
			}

			const response = await fetchApi({
				method: FETCH_API_METHODS.Get,
				url: '/user/me',
				pendingKey: STATE_STORE_KEYS.CurrentUser,
				token
			})

			if (response && response.data) {
				this.user = response.data
				if (response.data?.phone_number) {
					this.user.phone_number = response.data?.phone_number
				}
				if (response.data?.icon) {
					this.userIconUrl = response.data?.icon

					const imageUrlToBase64 = async (url: string): Promise<{
						base64?: string
						size?: number
					}> => {
						if (!url) {
							return {}
						}
						try {
							const response = await httpClient.get(
								url, { responseType: 'blob' }
							)
							const blob = response.data

							return new Promise((resolve, reject) => {
								const reader = new FileReader()
								reader.readAsDataURL(blob)

								reader.onloadend = () => {
									if (reader.result) {
										resolve({
											size: blob.size,
											base64: reader.result.toString()
										})
									} else {
										reject(new Error('Failed to read file data.'))
									}
								}

								reader.onerror = () => {
									reject(new Error('Error reading file data.'))
								}
							})
						} catch (error) {
							// eslint-disable-next-line no-console
							console.error('Error loading file from URL:', error)
							throw error
						}
					}
					const iconResponse = await imageUrlToBase64(
						response.data?.icon
					)
					if (iconResponse && iconResponse?.base64) {
						this.user.icon = iconResponse.base64
					}
				}
			}
		},
		async fetchUpdateUser ({
			token = '',
			user,
			editToken = ''
		}: {
			token?: string
			user: any
			editToken?: string
		}) {
			if (!token && !user && !editToken) {
				return
			}

			let params: Record<string, string> = {}

			if (editToken) {
				params = {
					'x-edit-token': editToken
				}
			}

			const response = await fetchApi({
				method: FETCH_API_METHODS.Patch,
				url: '/user/update',
				pendingKey: STATE_STORE_KEYS.UpdateUser,
				token,
				payload: {
					...user
				},
				params
			})

			if (response && response.data) {
				const fillEmptyKeys = (
					user: Partial<IUserStoreType['user']>,
					data: components['schemas']['UpdateUser']
				) => {
					Object.entries(user).forEach(([key, value]) => {
						if (!value) {
							const userKey = key as keyof IUserStoreType['user']
							// @ts-expect-error ___
							user[userKey] = data[userKey]
						}
					})
				}

				fillEmptyKeys(this.user, response.data)

				if (response.data.phone_number) {
					this.user.phone_number = response.data.phone_number
				}
			}
		},
		async fetchUpdateUserById ({
			token = '',
			id = undefined,
			user
		}: {
			token?: string
			id: number | undefined
			user: any
		}) {
			if (!token && !id) {
				return
			}

			await fetchApi({
				method: FETCH_API_METHODS.Patch,
				url: `/user/${id}/update`,
				pendingKey: STATE_STORE_KEYS.UpdateUser,
				token,
				payload: {
					...user
				}
			})
		},
		async fetchValidateUser (token: string = '', email: string) {
			if (!token || !email) {
				return
			}

			const response = await fetchApi({
				method: FETCH_API_METHODS.Post,
				url: '/user/applicant/validate',
				pendingKey: STATE_STORE_KEYS.ValidateUser,
				token,
				payload: {
					email
				}
			})

			if (response && response.data) {
				return response.data
			}
		},
		async fetchApplicantList (token: string = '') {
			if (!token) {
				return
			}

			const response = await fetchApi({
				method: FETCH_API_METHODS.Get,
				url: '/user/applicant/list?return_all=true',
				pendingKey: STATE_STORE_KEYS.UserList,
				token
			})

			if (response && response.data) {
				this.userList = response.data
			}
		},
		async fetchInviteUser ({
			token = '',
			email
		}: {
			token?: string
			email: string | undefined
		}) {
			if (!token && !email) {
				return
			}

			await fetchApi({
				method: FETCH_API_METHODS.Post,
				url: '/user/invite',
				pendingKey: STATE_STORE_KEYS.UpdateUser,
				token,
				payload: {
					email
				}
			})
		},
		async fetchChangePassword ({
			token,
			payload
		}: {
    token: string
    payload: {
      new_password: string
      new_password_confirm: string
    }
  }) {
			if (!token || isEmpty(payload)) {
				return
			}

			const response = await fetchApi({
				method: FETCH_API_METHODS.Post,
				url: '/user/change-password',
				pendingKey: STATE_STORE_KEYS.UserChangePassword,
				token,
				payload
			})

			if (response && response.data) {
				return response
			}
		},
		toggleDialogLogout () {
			this.isShowLogoutDialog = !this.isShowLogoutDialog
		}
	}
})

export enum ECP_TABLE_CLIENTS_KEYS {
  Name = 'Name',
  Email = 'Email',
  Phone_number = 'Telefonnummer',
  Trust_level = 'Vertrauensniveau',
  Projects = 'Projekte',
  Project_activity = 'Projektaktivität',
  Comment = 'Comment',
  Id = 'Kommentar'
}

export type TCPTrustLevel = 'High' | 'Low' | 'Medium' | '-'

export interface ICPTableClientsItemTypes {
  [ECP_TABLE_CLIENTS_KEYS.Name]: string
  [ECP_TABLE_CLIENTS_KEYS.Email]: string
  [ECP_TABLE_CLIENTS_KEYS.Phone_number]: string
  [ECP_TABLE_CLIENTS_KEYS.Trust_level]: TCPTrustLevel
  [ECP_TABLE_CLIENTS_KEYS.Projects]: string
  [ECP_TABLE_CLIENTS_KEYS.Project_activity]: string
  [ECP_TABLE_CLIENTS_KEYS.Comment]: string
  [ECP_TABLE_CLIENTS_KEYS.Id]: number
}

export interface ICPTableClientsPropTypes {
  modelValue: ICPTableClientsItemTypes[]
  loading?: boolean
}

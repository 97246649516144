export const formatFileSize = (sizeInBytes: number): string => {
	const sizes = ['Bytes', 'Kb', 'Mb', 'GB', 'TB'] as string[]
	const i = parseInt(
		Math.floor(Math.log(sizeInBytes) / Math.log(1024)).toString(),
		10
	)

	return sizeInBytes
		? `${Math.round(sizeInBytes / Math.pow(1024, i))} ${sizes[i]}`
		: ''
}

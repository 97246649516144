import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchGetDraftListPayload {
	token: IFetchApiProps['token']
	currentPage?: number
	pageSize?: boolean
	params?: {
		ordering?: string
	}
}

export const fetchApiGetDraftsList = async (
	payload: IFetchGetDraftListPayload
): Promise<
	components['schemas']['PaginatedGetProjectListList'] | undefined
> => {
	if (!payload.token || !payload.currentPage) {
		return
	}

	const url = payload.pageSize
		? `/project/list?is_draft=true&page_size=${payload.currentPage * 10}`
		: `/project/list?is_draft=true&page=${payload.currentPage}`

	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.CreationProject,
		token: payload.token,
		params: payload.params
	})

	if (response && response.data) {
		return response.data
	}
}

import { onMounted, ref } from 'vue'

import { useAuth0 } from '@auth0/auth0-vue'

export const useAccessToken = () => {
	const { getAccessTokenSilently } = useAuth0()

	const token = ref<string>('')

	onMounted(async () => {
		if (!token.value) {
			token.value = await getAccessTokenSilently()
		}
	})

	return { token }
}

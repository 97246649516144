import type { components } from 'schema'

import type { IFetchApiProps } from '~/api/axios.ts'
import { FETCH_API_METHODS, fetchApi } from '~/api/axios.ts'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiControllerList {
  token: IFetchApiProps['token']
  projectId?: number
  params?: {
    page?: string | number
    search?: string | number
		type_id?: string | number
  }
}

export interface IFetchApiFindController {
  token: IFetchApiProps['token']
  projectId: number
  payload: components['schemas']['FindProjectController']
}

export interface IFetchDeleteController {
  token: IFetchApiProps['token']
  projectId: number
  controllerId: number
}

export const fetchApiControllerList = async (
	payload: IFetchApiControllerList
) => {
	const url = payload.projectId
		? `/project/${payload.projectId}/controller/list`
		: '/product/controller/list'

	return await fetchApi({
		url,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ControllerList,
		params: {
			...payload.params,
			return_all: true
		}
	})
}

export interface IFetchControllerTypeList {
  token: IFetchApiProps['token']
  params?: {
    page?: string | number
    return_all?: boolean
    search?: string
    type?: number
    type_id?: number
  }
}

export const fetchControllerTypeList = async (
	payload: IFetchControllerTypeList
) => {
	return await fetchApi({
		url: '/product/controller/type/list',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ControllerTypeList,
		params: {
			...payload.params,
			return_all: true
		}
	})
}

export const fetchFindController = ({
	token,
	projectId,
	payload
}: IFetchApiFindController) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ControllerFind,
		url: `/project/${projectId}/controller/find`,
		payload
	})
}

export const fetchDeleteController = ({
	token,
	projectId,
	controllerId
}: IFetchDeleteController) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ControllerDelete,
		url: `/project/${projectId}/controller/${controllerId}/delete`
	})
}

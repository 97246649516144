export const useLink = (link?: string): any => {
	const decode = (text: string = ''): string => {
		try {
			return decodeURIComponent(`${text}`)
		} catch (_err) {
			return `${text}`
		}
	}

	const hasProtocol = (input: string): string | boolean => {
		const [protocol] = (
			input
				.replace(/\\/g, '/')
				.match(/([^:/]+:)?\/\/([^/@]+@)?(.*)/) || []
		).splice(1)

		return decode(protocol).length > 0
	}

	const isExternal = (route: string | {
    path: string
  } = link || ''): string | boolean => {
		if (!route) {
			return false
		}

		if (typeof route === 'string') {
			return hasProtocol(route)
		} else if (typeof route?.path === 'string') {
			return hasProtocol(route?.path)
		}
		return false
	}

	const appendQueryParameter = (
		query: Record<string, string | string[]>
	): string | undefined => {
		try {
			if (link) {
				const url = new URL(link)
				const paramArr = Object.entries(query || {})
				const params = new URLSearchParams(url.search)

				const resultUrl = url.href.split('?')[0]

				if (paramArr.length > 0) {
					paramArr.forEach((param) => {
						const [key, values] = param

						const value = Array.isArray(values) ? values[0] : values

						if (params.has(key)) {
							params.set(key, value)
						} else {
							params.append(key, value)
						}
					})
				}
				const stringifiedParams = params.toString()

				if (stringifiedParams.length > 0) {
					return `${resultUrl}?${stringifiedParams}`
				}

				return link
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
			return link
		}
	}

	return {
		appendQueryParameter,
		isExternal
	}
}

export enum CompanyManagementRoutes {
  ActivityLogs = 'activity-logs',
  CompanyManagement = 'company-management',
  Invoices = 'invoices',
  Certificates = 'certificates',
  Employees = 'employees',
  CheckLists = 'check-lists',
  DBProducts = 'db-products',
  Checklist = 'checklist',
  ChecklistItem = 'checklist-item',
  DBControllers = 'db-controllers',
  DBProtection = 'db-protection'
}

import { defineStore } from 'pinia'

import {
	createDocumentsChecklistItem,
	fetchApiDeleteChecklistItem,
	fetchApiGetCurrentChecklist,
	fetchGetChecklistItem
} from '~/api'
import type { ICPDocumentsChecklistStoreTypes } from '~/stores'

export const useDocumentsChecklistStore = defineStore('documentsChecklistStore', {
	state: (): ICPDocumentsChecklistStoreTypes => ({
		checklist: {},
		checklistItem: {}
	}),
	actions: {
		async fetchCurrentChecklist (token: string, projectId: number) {
			const response = await fetchApiGetCurrentChecklist({ token, projectId })

			if (response) {
				this.checklist = response
			}
		},
		async deleteChecklistItem (payload: {
      token: string
      checklistItemId: number
      isCustom?: boolean
    }) {
			if (!this.checklist.id || !payload.token) return

			const response = await fetchApiDeleteChecklistItem({
				token: payload.token,
				checklistItemId: payload.checklistItemId,
				checklistId: this.checklist.id,
				isCustom: payload.isCustom
			})

			if (response) {
				this.checklist.items = this.checklist.items?.filter(
					(item: any) => item.id !== payload.checklistItemId
				)
			}
		},
		async createChecklistItem (payload: {
      token: string
      projectId: number
      isCustom?: boolean
      data: {
        name: string
        description: string
      }
    }) {
			if (!this.checklist.id) return

			const response = await createDocumentsChecklistItem({
				...payload,
				checklistId: this.checklist.id
			})

			if (response) {
				await this.fetchCurrentChecklist(
					payload.token,
					payload.projectId
				)
			}
		},
		async fetchChecklistItem (payload: {
      token: string
      checklistItemId: number
      checklistId: number
      projectId?: number
    }) {
			const response = await fetchGetChecklistItem(payload)
			if (response) {
				this.checklistItem = response
			}
		}
	}
})

export enum ECP_PRODUCT_TABLE_COLUMNS {
  Manufacture = 'Herstellung',
  Type = 'Typ',
  LastUpdate = 'Letzte Aktualisierung',
  Action = 'Aktion',
}

export interface ICPProductTableData {
  [ECP_PRODUCT_TABLE_COLUMNS.Manufacture]: string
  [ECP_PRODUCT_TABLE_COLUMNS.Type]: string
  [ECP_PRODUCT_TABLE_COLUMNS.LastUpdate]: string
  [ECP_PRODUCT_TABLE_COLUMNS.Action]: number
}

export interface ICPProductTableProps {
  data: ICPProductTableData[]
  loading?: boolean
}

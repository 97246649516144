import type { RouteLocationNormalized } from 'vue-router'

import type { Auth0VueClient } from '@auth0/auth0-vue'
import { useAuth0 } from '@auth0/auth0-vue'

export const authMiddleware = async (
	to: RouteLocationNormalized
): Promise<void> => {
	const { loginWithRedirect }: Auth0VueClient = useAuth0()

	if (to.name === 'login') {
		loginWithRedirect()
	}
}

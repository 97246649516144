export enum ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS {
  ChecklistItemName = 'Name des Checklistenelements',
  Questions = 'Fragen',
  LastUpdate = 'Letzte Aktualisierung',
  Actions = 'Aktionen'
}

export interface ICPCompanyChecklistItemsTableRow {
  [ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.ChecklistItemName]: string
  [ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.Questions]: number
  [ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.LastUpdate]: {
    updatedAt: string
    updatedBy: string
  }
  [ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.Actions]: number
}

export interface ICPCompanyChecklistItemsTableProps {
  data: ICPCompanyChecklistItemsTableRow[]
  loading?: boolean
}

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

import type { ICPFileUploadPropTypes } from '~/components/base'
import { CPButton, CPFileUploadItem } from '~/components/base'

import { formatFileSize } from './'

const props = withDefaults(
	defineProps<ICPFileUploadPropTypes>(),
	{
		accept: 'application/pdf',
		maxSize: 512,
		isEditable: true,
		multiple: false
	}
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'delete'): void
  (e: 'update:modelValue', payload: any): void
  (e: 'error', size: string): void
}>()

const inputRef = ref()
const isDelete = ref(false)

const model = computed({
	set: (value) => {
		emit('update:modelValue', value)
	},
	get: () => props.modelValue
})

const onClick = (): void => {
	emit('click')
	inputRef.value.click()
}

const onDeleteFile = (value?: number): void => {
	isDelete.value = true

	if (model.value?.length > 1 && (value === 0 || value)) {
		model.value = [
			...model.value.slice(0, value),
			...model.value.slice(value + 1)
		]
	} else {
		model.value = undefined
	}

	emit('delete')
}

const fileName = computed(() => {
	return Array.isArray(model.value)
		? model.value[0]?.name
		: model.value.name
})

const fileSize = computed(() => {
	return Array.isArray(model.value)
		? model.value[0]?.size
		: model.value?.size
})

watch(model, (value, oldValue) => {
	if (props.multiple) {
		if (!checkRAWFile(value) && !!checkRAWFile(oldValue)) {
			return
		}

		if (oldValue?.length && !isDelete.value) {
			model.value?.push(...oldValue)
		}
	}

	isDelete.value = false

	if (model.value && model.value[0]) {
		if (value[0]?.size > props.maxSize * 1024 * 1024) {
			emit('error', formatFileSize(value[0]?.size))
			model.value = undefined
		}
	}
})

const checkRAWFile = (value: any) => {
	return value?.every((item: any) => {
		return !(item instanceof File)
	})
}

const blobUrlToArrayBuffer = async (blobUrl: string) => {
	const response = await fetch(blobUrl)
	return await response.blob()
}

const setFileList = async () => {
	if (!checkRAWFile(model.value)) {
		return model.value
	}

	const list: Promise<Blob>[] = model.value?.map(async (item: any) => {
		return await blobUrlToArrayBuffer(item.file_key)
	})

	const values = await Promise.all(list)

	return values.map((value, index) => {
		return new File([value], model.value[index].name, { type: value.type })
	})
}

onMounted(async () => {
	model.value = await setFileList()
})
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
	<div
		:class="[
			'cp-file-upload',
			{
        'cp-file-upload--multiple': multiple
			},
			$attrs.class
		]"
	>
		<CPButton
			v-if="!model || multiple"
			class="cp-file-upload__button"
      :disabled="disabled"
			color="accent"
			icon-prepend="upload"
			@click="onClick"
		>
			{{ label || 'Upload von Dokumenten' }}
			<VFileInput
				ref="inputRef"
				v-model="model"
				class="cp-file-upload__input"
				:accept="accept"
        :multiple="multiple"
			/>
		</CPButton>
    <div class="cp-file-upload__list" v-if="model?.length">
      <CPFileUploadItem
        v-for="(file, index) in model"
        :key="file.name"
        :name="file.name"
        :size="file.size"
        :is-editable="isEditable"
        @delete="onDeleteFile(index)"
      />
    </div>
    <CPFileUploadItem
      v-else-if="model"
      :name="fileName"
      :size="fileSize"
      :is-editable="isEditable"
      @delete="onDeleteFile"
    />
	</div>

</template>

<style lang="scss">
	.cp-file-upload {
		@apply
			inline-flex
		;

    &--multiple {
      @apply
        flex-wrap
        gap-6
      ;

      .cp-file-upload__button {
        @apply
          order-last
        ;
      }

      .cp-file-upload__list {
        @apply
          w-full
        ;
      }
    }

		&__button {
			@apply
				relative
				cursor-pointer
				overflow-hidden
			;
		}

		&__input {
			&, &.v-input {
				@apply
					hidden
					invisible
				;
			}
		}

    &__list {
      @apply
        grid
        gap-6
      ;
    }
	}
</style>

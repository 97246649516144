import { defineStore } from 'pinia'

import type {
	IFetchApiActivityLogAllList,
	IFetchApiActivityLogList,
	IFetchApiActivityLogUserAllList,
	IFetchApiActivityLogUserList
} from '~/api'
import {
	fetchApiActivityLogAllList,
	fetchApiActivityLogList,
	fetchApiActivityLogUserAllList,
	fetchApiActivityLogUserList
} from '~/api'
import type { IActivityLogsStore } from '~/stores'

export const useActivityLogStore = defineStore('ActivityLogStore', {
	state: (): IActivityLogsStore => ({
		activityLogUserList: [],
		activityLogList: {},
		currentPage: 1,
		isPagination: false,
		/* Activity log for company management */
		activityLogUserAllList: [],
		activityLogAllList: [],
		currentPageAllList: 1,
		isPaginationAllList: false
	}),
	getters: {
		currentActivityLog (state: IActivityLogsStore) {
			return (id: string) => state.activityLogList[id] || []
		}
	},
	actions: {
		resetActivityLogAllState () {
			this.currentPageAllList = 1
			this.activityLogAllList = []
			this.isPaginationAllList = false
		},
		resetActivityLogState () {
			this.currentPage = 1
			this.activityLogList = {}
			this.isPagination = false
		},
		async fetchActivityLogList (
			payload: Omit<IFetchApiActivityLogList, 'page'>
		) {
			const response = await fetchApiActivityLogList({
				id: payload.id,
				token: payload.token,
				params: payload.params
			})

			if (response && response.data) {
				this.currentPage = response.data.next ??
					(response.data.previous + 1) ??
					1

				if (this.activityLogList[payload.id]) {
					this.activityLogList[payload.id].push(
						...response.data.results
					)
				} else {
					this.activityLogList[payload.id] = response.data.results
				}

				this.isPagination = !!response.data.next
			}
		},
		async fetchActivityLogUserList (
			payload: IFetchApiActivityLogUserList
		) {
			const response = await fetchApiActivityLogUserList({
				id: payload.id,
				token: payload.token
			})

			if (response && response.data) {
				this.activityLogUserList = response.data
			}
		},
		async fetchActivityLogAllList (
			payload: IFetchApiActivityLogAllList
		) {
			const response = await fetchApiActivityLogAllList({
				token: payload.token,
				params: payload.params
			})

			if (response && response.data) {
				this.currentPageAllList = response.data.next ??
					(response.data.previous + 1) ??
					1
				this.activityLogAllList?.push(...response.data.results)
				this.isPaginationAllList = !!response.data.next
			}
		},
		async fetchActivityLogAllUserList (
			payload: IFetchApiActivityLogUserAllList
		) {
			const response = await fetchApiActivityLogUserAllList({
				token: payload.token,
				params: payload.params
			})

			if (response && response.data) {
				this.activityLogUserAllList = response.data
			}
		}
	}
})

import { ref } from 'vue'

const isSnackbarVisible = ref(false)
const snackbarMessage = ref<string | string[]>('')
const snackbarColor = ref('cp-neutral-600')

export const useSnackbar = () => {
	const showSnackbar = (
		message: string | string[],
		color: 'cp-base-red' | 'cp-neutral-600' = 'cp-neutral-600'
	) => {
		snackbarMessage.value = message
		snackbarColor.value = color
		isSnackbarVisible.value = true
	}

	return {
		isSnackbarVisible,
		snackbarMessage,
		snackbarColor,
		showSnackbar
	}
}

import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export const fetchApiGetProjectCount = async (
	payload: {
		token: IFetchApiProps['token']
	}
) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/project/count',
		pendingKey: STATE_STORE_KEYS.ProjectCount,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetProjectAuthors = async (
	payload: {
    token: IFetchApiProps['token']
  }
): Promise<components['schemas']['GetUser'][] | undefined> => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/project/authors?return_all=true',
		pendingKey: STATE_STORE_KEYS.ProjectAuthor,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetProjectManagers = async (
	payload: {
    token: IFetchApiProps['token']
  }

): Promise<components['schemas']['GetUser'][] | undefined> => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/project/manager?return_all=true',
		pendingKey: STATE_STORE_KEYS.ProjectManagers,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

import { defineStore } from 'pinia'
import type { components } from 'schema'

import {
	FETCH_API_METHODS,
	fetchApi
} from '~/api'
import type {
	ICPFormCreateAccountFormData,
	ICPFormPersonalDetailsFormData
} from '~/components/form'
import type { ICreateAccountStoreTypes } from '~/stores'
import { STATE_STORE_KEYS } from '~/types'

const accountInitialState: ICreateAccountStoreTypes['account'] = {
	email: '',
	password: '',
	confirmPassword: ''
}

const personalInfoInitialState: ICreateAccountStoreTypes['personalInfo'] = {
	index: '',
	city: '',
	street: '',
	firstName: '',
	lastName: '',
	companyName: '',
	phoneNumber: '',
	gender: null
}

export const useCreateAccountStore = defineStore('createAccountStore', {
	state: (): ICreateAccountStoreTypes => ({
		editToken: '',
		account: { ...accountInitialState },
		personalInfo: { ...personalInfoInitialState }
	}),
	getters: {
		getEditToken (state: ICreateAccountStoreTypes) {
			return state.editToken
		},
		getUserEmail (state: ICreateAccountStoreTypes) {
			return state.account!.email
		}
	},
	actions: {
		updateEditToken (payload: string) {
			this.editToken = payload
		},
		resetCreateAccountState () {
			this.account = { ...accountInitialState }
			this.personalInfo = { ...personalInfoInitialState }
		},
		updateAccountData (payload: ICPFormCreateAccountFormData) {
			this.account = {
				...this.account,
				...payload
			}
		},
		updatePersonalInfoData (payload: ICPFormPersonalDetailsFormData) {
			this.personalInfo = {
				...this.personalInfo,
				...payload
			}
		},
		async createUser (
			payload: components['schemas']['CreateUser']
		) {
			const response = await fetchApi({
				url: '/user/',
				method: FETCH_API_METHODS.Post,
				pendingKey: STATE_STORE_KEYS.CreatUser,
				payload
			})

			if (response && response.data) {
				this.updateEditToken(response.data.edit_token)
			}
		},
		async fetchEmailVerification (
			payload: Pick<components['schemas']['UpdateUser'], 'email'>
		) {
			return await fetchApi({
				url: '/user/email-verification',
				method: FETCH_API_METHODS.Post,
				pendingKey: STATE_STORE_KEYS.EMailVerification,
				payload
			})
		}
	}
})

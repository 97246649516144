export enum LAppLayoutsEnum {
  default = 'default',
  signUp = 'signUp',
  project = 'project',
  singleProject = 'singleProject'
}

export const AppLayoutToFileMap: Record<LAppLayoutsEnum, string> = {
	default: 'LDefault.vue',
	signUp: 'LSignUp.vue',
	project: 'LProjectCreation.vue',
	singleProject: 'LSingleProject.vue'
}

import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useCreateAccountStore, useUserStore } from '~/stores'

export const personalDetailsMiddleWare = async (
	_: RouteLocationNormalized,
	__: RouteLocationNormalized,
	next: NavigationGuardNext
): Promise<void> => {
	const userStore = useUserStore()
	const accountStore = useCreateAccountStore()
	// eslint-disable-next-line @typescript-eslint/typedef
	const { account } = storeToRefs(accountStore)
	const { user } = storeToRefs(userStore)

	const isEmptyAccount = Object.values(account.value).some((value) => !!value)

	if (!isEmptyAccount && !user.value.is_invited) {
		next({ name: 'account-create' })
	} else {
		next()
	}
}

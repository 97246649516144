import type { RouteRecordRaw } from 'vue-router'

import { DemoRoutesNamesEnum } from '~/router/modules/demo/demoTypes'

export const getDemoRoutes = (): RouteRecordRaw => {
	return {
		path: '/demo',
		name: 'demo',
		component: () => import('~/pages/demo.vue'),
		children: [
			{
				path: 'infinity-scroll',
				name: DemoRoutesNamesEnum.infinityScroll,
				component: () => import('~/playground/pages/base/infinity-scroll.vue')
			},
			{
				path: 'multiselect',
				name: DemoRoutesNamesEnum.multiSelect,
				component: () => import('~/playground/pages/base/multiselect.vue')
			},
			{
				path: 'multi-input',
				name: DemoRoutesNamesEnum.multiInput,
				component: () => import('~/playground/pages/form/multi-input.vue')
			},
			{
				path: 'sidebar',
				name: DemoRoutesNamesEnum.sidebar,
				component: () => import('~/playground/pages/base/sidebar.vue')
			},
			{
				path: 'overlay',
				name: DemoRoutesNamesEnum.overlay,
				component: () => import('~/playground/pages/base/overlay.vue')
			},
			{
				path: 'input-calendar',
				name: DemoRoutesNamesEnum.inputCalendar,
				component: () => import('~/playground/pages/form/input-calendar.vue')
			},
			{
				path: 'file-viewer',
				name: DemoRoutesNamesEnum.fileViewer,
				component: () => import('~/playground/pages/file-viewer.vue')
			},
			{
				path: '',
				name: DemoRoutesNamesEnum.colors,
				component: () => import('~/playground/pages/colors.vue')
			},
			{
				path: 'action-item',
				name: DemoRoutesNamesEnum.actionItem,
				component: () => import('~/playground/pages/action-item.vue')
			},
			{
				path: 'avatar',
				name: DemoRoutesNamesEnum.avatar,
				component: () => import('~/playground/pages/avatar.vue')
			},
			{
				path: 'buttons',
				name: DemoRoutesNamesEnum.buttons,
				component: () => import('~/playground/pages/buttons.vue')
			},
			{
				path: 'controls',
				name: DemoRoutesNamesEnum.controls,
				component: () => import('~/playground/pages/controls.vue')
			},
			{
				path: 'dialog',
				name: DemoRoutesNamesEnum.dialog,
				component: () => import('~/playground/pages/dialog.vue')
			},
			{
				path: 'dropdown',
				name: DemoRoutesNamesEnum.dropdown,
				component: () => import('~/playground/pages/dropdown.vue')
			},
			{
				path: 'icons',
				name: DemoRoutesNamesEnum.icons,
				component: () => import('~/playground/pages/icons.vue')
			},
			{
				path: 'inputs',
				name: DemoRoutesNamesEnum.inputs,
				component: () => import('~/playground/pages/inputs.vue')
			},
			{
				path: 'link',
				name: DemoRoutesNamesEnum.link,
				component: () => import('~/playground/pages/link.vue')
			},
			{
				path: 'progress-bar',
				name: DemoRoutesNamesEnum.progressBar,
				component: () => import('~/playground/pages/progress-bar.vue')
			},
			{
				path: 'segment-control',
				name: DemoRoutesNamesEnum.segmentControl,
				component: () => import('~/playground/pages/segment-control.vue')
			},
			{
				path: 'select',
				name: DemoRoutesNamesEnum.select,
				component: () => import('~/playground/pages/select.vue')
			},
			{
				path: 'snackbar',
				name: DemoRoutesNamesEnum.snackbar,
				component: () => import('~/playground/pages/snackbar.vue')
			},
			{
				path: 'stepper',
				name: DemoRoutesNamesEnum.stepper,
				component: () => import('~/playground/pages/stepper.vue')
			},
			{
				path: 'tab-navigation',
				name: DemoRoutesNamesEnum.tabNavigation,
				component: () => import('~/playground/pages/tab-navigation.vue')
			},
			{
				path: 'tag',
				name: DemoRoutesNamesEnum.tag,
				component: () => import('~/playground/pages/tag.vue')
			},
			{
				path: 'tooltip',
				name: DemoRoutesNamesEnum.tooltip,
				component: () => import('~/playground/pages/tooltip.vue')
			},
			{
				path: 'file-upload',
				name: DemoRoutesNamesEnum.fileUpload,
				component: () => import('~/playground/pages/file-upload.vue')
			},
			{
				path: 'alert',
				name: DemoRoutesNamesEnum.alert,
				component: () => import('~/playground/pages/alert.vue')
			},
			{
				path: 'text-editor',
				name: DemoRoutesNamesEnum.textEditor,
				component: () => import('~/playground/pages/text-editor.vue')
			},
			{
				path: 'typography',
				name: DemoRoutesNamesEnum.typography,
				component: () => import('~/playground/pages/typography.vue')
			},
			{
				path: 'breadcrumbs',
				name: DemoRoutesNamesEnum.breadcrumbs,
				component: () => import('~/playground/pages/breadcrumbs.vue')
			},
			{
				path: 'counter',
				name: DemoRoutesNamesEnum.counter,
				component: () => import('~/playground/pages/counter.vue')
			},
			{
				path: 'radio-block',
				name: DemoRoutesNamesEnum.radioBlock,
				component: () => import('~/playground/pages/radio-block.vue')
			},
			{
				path: 'table',
				name: 'table',
				component: () => import('~/playground/pages/table.vue')
			},
			{
				path: 'accordion',
				name: DemoRoutesNamesEnum.accordion,
				component: () => import('~/playground/pages/accordion.vue')
			},
			{
				path: 'password-field',
				name: DemoRoutesNamesEnum.passwordField,
				component: () => import('~/playground/pages/password-filed.vue')
			}
		]
	}
}

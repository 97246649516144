import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

const pendingKey = STATE_STORE_KEYS.EmployeesInteractive

export const fetchApiGetEmployees = async (payload: {
  token: IFetchApiProps['token']
  params?: {
    page?: number
    search?: string
    role?: string
    status?: string
  }
}): Promise<components['schemas']['PaginatedListEmployeeList'] | undefined> => {
	if (!payload.token) return
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		pendingKey,
		url: 'user/employee/list',
		params: payload.params,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteEmployee = (payload: {
  token: IFetchApiProps['token']
  id: number
}) => {
	if (!payload.token) return
	return fetchApi({
		method: FETCH_API_METHODS.Delete,
		pendingKey,
		url: `/user/employee/${payload.id}/delete`,
		token: payload.token
	})
}

export const fetchApiCancelEmployeeInvitation = (payload: {
  token: IFetchApiProps['token']
  id: number
}) => {
	if (!payload.token) return
	return fetchApi({
		method: FETCH_API_METHODS.Get,
		pendingKey,
		url: `/user/employee/${payload.id}/cancel-invite`,
		token: payload.token
	})
}

export const fetchApiInviteEmployee = (payload: {
  token: IFetchApiProps['token']
  role: string
  projects: number[]
  emails: string[]
}) => {
	if (!payload.token) return
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		pendingKey,
		url: '/user/employee/invite',
		payload: {
			emails: payload.emails,
			role: payload.role,
			projects: payload.projects
		},
		token: payload.token
	})
}

export const fetchApiReInviteEmployee = (payload: {
  token: IFetchApiProps['token']
  id: number
  role: string
  projects?: number[]
}) => {
	return fetchApi({
		token: payload.token,
		url: `/user/employee/${payload.id}/re-invite`,
		pendingKey,
		method: FETCH_API_METHODS.Post,
		payload: {
			role: payload.role,
			projects: payload.projects
		}
	})
}

export interface IFetchApiGetEmployeeById {
	token: IFetchApiProps['token']
	id: string | number
}

export const fetchApiGetEmployeeById = async (
	payload: IFetchApiGetEmployeeById
) => {
	return await fetchApi({
		url: `/user/employee/${payload.id}`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.EmployeeById
	})
}

export interface IFetchApiGetEmployeeProjects {
	token: IFetchApiProps['token']
	id: number
	params?: {
		search?: string
		page?: number
		ordering?: string
		return_all?: boolean
	}
}

export const fetchApiGetEmployeeProjects = async (
	payload: IFetchApiGetEmployeeProjects
) => {
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/user/employee/${payload.id}/project/list`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.EmployeeProjects,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchApiGetEmployeeList {
	token: IFetchApiProps['token']
	params?: {
		page?: number
		role?: string | string[]
		search?: string
		status?: string
		return_all?: boolean
	}
}

export const fetchApiGetEmployeeList = async (
	payload: IFetchApiGetEmployeeList
) => {
	// /api/user/employee/list
	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: '/user/employee/list',
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.EmployeeLis,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchApiReassignEmployeeProjects {
	token: IFetchApiProps['token']
	id: number
	users: number[]
	projects: number[]
}

export const fetchApiReassignEmployeeProjects = async (
	payload: IFetchApiReassignEmployeeProjects
) => {
	return await fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/user/${payload.id}/project/re-assign`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.EmployeeProjectsReassign,
		payload: {
			users: payload.users,
			projects: payload.projects
		}
	})
}

export interface IFetchApiEmployeeUserUpdate {
	token: IFetchApiProps['token']
	id: number
	payload?: {
		is_user_active?: boolean
		role?: string
		is_invited?: boolean
	}
}

export const fetchApiEmployeeUserUpdate = async (
	payload: IFetchApiEmployeeUserUpdate
) => {
	return await fetchApi({
		method: FETCH_API_METHODS.Patch,
		url: `/user/employee/${payload.id}/update`,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.EmployeeUserUpdate,
		payload: payload.payload
	})
}

<script lang="ts" setup>
import type { ICPSpinnerPropTypes } from '~/components/base'

withDefaults(
	defineProps<ICPSpinnerPropTypes>(),
	{
		size: 'default',
		color: '#ffffff'
	}
)

</script>

<script lang="ts">
export default {
	inheritAttrs: false
}
</script>

<template>
  <div
    :style="{'border-color': color }"
    :class="[
      $attrs.class,
      'cp-loading-spinner',
      {'cp-loading-spinner--sm': size === 'sm' },
      {'cp-loading-spinner--lg': size === 'lg' },
      {'cp-loading-spinner--xl': size === 'xl' },
    ]"
  />
</template>

<style lang="scss">
.cp-loading-spinner {
  width: 22px;
  height: 22px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid white;
  animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;

  &--sm {
    @apply
      w-4
      h-4
    ;
  }

  &--lg {
    @apply
      w-7
      h-7
    ;
  }

  &--xl {
    @apply
      w-9
      h-9
    ;
  }
}
@keyframes l20-1{
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}
</style>

import { defineStore } from 'pinia'

import type { IFetchApiGetMemberProjects } from '~/api'
import { fetchApiGetClientById, fetchApiGetMemberProjects } from '~/api'

import type { IMemberStoreTypes } from '.'

export const useMemberStore = defineStore('memberStore', {
	state: (): IMemberStoreTypes => ({
		currentPage: 1,
		resultCount: 0,
		isPagination: true,
		currentMemberId: undefined,
		member: {},
		projects: []
	}),
	actions: {
		resetMemberId () {
			this.currentMemberId = undefined
		},
		resetMemberList () {
			this.projects = []
			this.currentPage = 1
			this.isPagination = true
		},
		async fetchUseMember (payload: {
			token: string
			id: number
		}) {
			const response = await fetchApiGetClientById({
				token: payload.token,
				id: payload.id
			})

			if (response) {
				this.member = response
				this.currentMemberId = response.id
				return response
			}
		},
		async fetchMemberProjects (
			payload: IFetchApiGetMemberProjects
		) {
			const response = await fetchApiGetMemberProjects({
				token: payload.token,
				id: payload.id,
				params: {
					page: this.currentPage,
					...payload.params
				}
			})

			if (response) {
				this.currentPage = response.next ??
          (response.previous + 1) ??
          1

				this.projects?.push(...response.results)
				this.isPagination = !!response.next
			}
		}
	}
})

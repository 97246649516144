export enum CP_LINK_TARGET {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top'
}

export interface CPLinkPropsTypes {
  wrapper?: boolean
  name?: string
  href: string
  to: any
  target?: CP_LINK_TARGET
  query: Record<string, string | string[]>
}

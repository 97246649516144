import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiCertificateList {
  token: IFetchApiProps['token']
  params?: {
    page?: string | number
    search?: string | number
    status?: any
  }
}

export interface IFetchApiCertificateDelete {
  token: IFetchApiProps['token']
  projectId: number
  certificateId: number
}

export const fetchApiCertificateList = async (
	payload: IFetchApiCertificateList
) => {
	return await fetchApi({
		url: '/project/certificate/list',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.CertificateList,
		params: payload.params
	})
}

export interface IFetchApiCertificateListByProject {
	token: IFetchApiProps['token']
	id: string | number
	forceFetch?: boolean
	params?: {
		page?: string | number
		return_all?: boolean
	}
}

export const fetchApiCertificateListByProject = async (
	payload: IFetchApiCertificateListByProject
) => {
	return await fetchApi({
		url: `/project/${payload.id}/certificate/list`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.CertificateListByProject,
		params: payload.params
	})
}

export interface IFetchApiDeleteCertificateById {
	token: IFetchApiProps['token']
	id: string | number
	certificateId: string | number
}

export const fetchApiDeleteCertificateById = async (
	payload: IFetchApiDeleteCertificateById
) => {
	return await fetchApi({
		url: `/project/${payload.id}/certificate/${payload.certificateId}/delete`,
		token: payload.token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.CertificateDeleteById
	})
}

export interface IFetchApiCertificateGenerate {
	token: IFetchApiProps['token']
	projectId: string | number
}

export const fetchApiCertificateGenerate = async (
	payload: IFetchApiCertificateGenerate
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/certificate/generate`,
		token: payload.token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.CertificateGenerateById
	})
}

export interface IFetchApiCertificateDownload {
	token: IFetchApiProps['token']
	id: string | number
	certificateId: string | number
}

export const fetchApiCertificateDownload = async (
	payload: IFetchApiCertificateDownload
) => {
	return await fetchApi({
		url: `/project/${payload.id}/certificate/${payload.certificateId}/download`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.CertificateDownload
	})
}

export interface IFetchApiCertificateNotifyAll {
	token: IFetchApiProps['token']
	id: string | number
	certificateId: string | number
}

export const fetchApiCertificateNotifyAll = async (
	payload: IFetchApiCertificateNotifyAll
) => {
	return await fetchApi({
		url: `/project/${payload.id}/certificate/${payload.certificateId}/notify-all`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.CertificateNotifyAll
	})
}

export const fetchApiCertificateDelete = ({
	token,
	projectId,
	certificateId
}: IFetchApiCertificateDelete) => {
	return fetchApi({
		url: `/project/${projectId}/certificate/${certificateId}/delete`,
		token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.CertificateDeleteById
	})
}

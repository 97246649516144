export enum ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS {
  Name = 'Name',
  Email = 'Email',
  Role = 'Rolle',
  Status = 'Status',
  Projects = 'Projekte',
  LastActivity = 'Letzte Aktivität',
  Actions = 'Aktionen'
}

export enum ECP_COMPANY_EMPLOYEES_TABLE_ACTIONS {
  Delete = 'Löschen',
  CancelInvitation = 'Einladung stornieren',
  ResendInvitation = 'Einladung erneut senden',
}

export enum ECP_COMPANY_EMPLOYEES_STATUS {
  Active = 'Aktiv',
  Blocked = 'Blockiert',
  Invited = 'Einladung verschickt',
  InvitationExpired = 'Einladung abgelaufen',
}

export interface ICPCompanyEmployeesTableRow {
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Name]: string
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Email]: string
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Role]: string
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Status]: string
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Projects]: number
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.LastActivity]: string
  [ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Actions]: {
    status: string
    id: number
  }
}

export interface ICPCompanyEmployeesTableProps {
  data: ICPCompanyEmployeesTableRow[]
  hasActions?: boolean
  loading?: boolean
}

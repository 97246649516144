import { toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useProjectCreationStore } from '~/stores'

export const certificationMiddleWare = async (
	_: RouteLocationNormalized,
	__: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const projectStore = useProjectCreationStore()
	const { powerPlantAddress, applicantInformation } = storeToRefs(projectStore)
	const { applicant } = toRefs(applicantInformation.value)

	const isEmptyApplicant = Object.values(applicant.value).some((value) => !!value)
	const isEmptyPowerPlantAddress = Object.values(powerPlantAddress.value).some((value) => !!value)

	if (!isEmptyApplicant && !isEmptyPowerPlantAddress) {
		next('project-creation')
	} else {
		next()
	}
}

import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import type { IStateStoreType } from '~/stores'

export const useStateStore = defineStore('stateStore', {
	state: (): IStateStoreType => ({
		error: {},
		loading: {}
	}),
	getters: {
		getLoadingStatus (state: IStateStoreType) {
			return (pendingKey: string): boolean => state.loading[pendingKey]
		},
		getErrorStatus (state: IStateStoreType) {
			return (pendingKey: string): string | string[] => state.error[pendingKey]
		}
	},
	actions: {
		updateErrorStatus (
			payload: unknown,
			pendingKey: string
		) {
			const defaultError = 'Irgendetwas stimmt nicht! Bitte versuchen Sie es später noch einmal.'

			if (isAxiosError(payload)) {
				if (Array.isArray(payload.response?.data)) {
					this.error[pendingKey] = payload.response?.data.map((error) => {
						return error
					}).join('')
				} else {
					const errors: Record<string, string[]> = payload.response?.data
					if (errors) {
						const messages: string[] = []

						const extractErrors = (obj: any, path = '') => {
							if (Array.isArray(obj)) {
								obj.forEach((item) => extractErrors(item, path))
							} else if (
								typeof obj === 'object' && obj !== null
							) {
								Object.entries(obj).forEach(([key, value]) => {
									const newPath = path
										? `${path}.${key}`
										: key
									extractErrors(value, newPath)
								})
							} else if (typeof obj === 'string') {
								const formattedPath = path.replace(/[._]/g, ' ')
								messages.push(`${formattedPath}: ${obj}`)
							}
						}

						extractErrors(errors)
						this.error[pendingKey] = messages.length
							? messages
							: defaultError
					} else {
						this.error[pendingKey] = defaultError
					}
				}
			} else {
				this.error[pendingKey] = defaultError
			}
		},
		updateLoadingStatus (payload: boolean, pendingKey: string) {
			this.loading[pendingKey] = payload
		}
	}
})

import { defineStore, storeToRefs } from 'pinia'

import type { IFetchApiProps } from '~/api'
import {
	fetchApiGetFileFromUrl,
	fetchApiGetTeamById,
	fetchApiGetTeamMemberProjectsList,
	fetchApiGetTeamMembersList,
	fetchPostAddTeamMembers
} from '~/api'
import { useStateStore } from '~/stores'
import { STATE_STORE_KEYS } from '~/types'

import type { ITeamStoreTypes } from '.'

export const useTeamStore = defineStore('teamStore', {
	state: (): ITeamStoreTypes => ({
		members: {
			list: [],
			currentPage: 1,
			resultCount: 0,
			isPagination: true
		},
		projects: {
			list: [],
			currentPage: 1,
			resultCount: 0,
			isPagination: true
		},
		currentTeam: {},
		avatars: []
	}),
	getters: {
		getLoadingTeamState () {
			const stateStore = useStateStore()
			const { getLoadingStatus } = storeToRefs(stateStore)

			return getLoadingStatus.value(
				STATE_STORE_KEYS.TeamApiInteractive
			)
		},
		getErrorTeamMsg () {
			const stateStore = useStateStore()
			const { getErrorStatus } = storeToRefs(stateStore)

			return getErrorStatus.value(
				STATE_STORE_KEYS.TeamApiInteractive
			)
		}
	},
	actions: {
		resetMembersGetListState () {
			this.members = {
				list: [],
				currentPage: 1,
				resultCount: 0,
				isPagination: true
			}
		},
		resetMemberProjectsGetListState () {
			this.projects = {
				list: [],
				currentPage: 1,
				resultCount: 0,
				isPagination: true
			}
		},
		async fetchMembersList (payload: {
			token: IFetchApiProps['token']
			id: number
		}) {
			const response = await fetchApiGetTeamMembersList({
				currentPage: this.members.currentPage,
				id: payload.id,
				token: payload.token
			})

			if (response) {
				// @ts-expect-error ___
				this.members.currentPage = response?.next ??
					response?.previous ?? 1
				this.members.resultCount = response.count as number
				this.members.isPagination = !!response?.next &&
					!(this.members.list.length === this.members.currentPage)

				if (response?.results) {
					if (this.members.list.length) {
						this.members.list.push(...response.results)
					} else {
						this.members.list = response.results
					}
				}
				return response
			}
		},
		async fetchMemberProjectsList (payload: {
			token: IFetchApiProps['token']
			id: number
      ordering?: string
		}) {
			const response = await fetchApiGetTeamMemberProjectsList({
				params: {
					page: this.projects.currentPage,
					ordering: payload.ordering
				},
				id: payload.id,
				token: payload.token
			})

			if (response) {
				this.projects.currentPage = response?.next ??
					response?.previous ?? 1
				this.projects.resultCount = response.count as number
				this.projects.isPagination = !!response?.next &&
					!(this.projects.list.length === this.projects.currentPage)

				if (response?.results) {
					if (this.projects.list.length) {
						this.projects.list.push(...response.results)
					} else {
						this.projects.list = response.results
					}
				}
			}
		},
		async fetchUseTeam (payload: {
			token: string
			id: number
		}) {
			const response = await fetchApiGetTeamById({
				token: payload.token,
				id: payload.id
			})

			if (response) {
				this.currentTeam = response
				return response
			}
		},
		async fetchAddTeamMembers (payload: {
			token: string
			members: number[]
			id: number
		}) {
			await fetchPostAddTeamMembers({
				members: payload.members,
				token: payload.token,
				id: payload.id
			})

			this.resetMembersGetListState()

			await Promise.all([
				this.fetchMembersList({
					token: payload.token,
					id: payload.id
				}),
				this.fetchUseTeam({
					token: payload.token,
					id: payload.id
				})
			])

			await this.fetchTeamMemberAvatars()
		},
		async fetchTeamMemberAvatars () {
			if (this.currentTeam?.members) {
				const getInitials = (name: string): string => {
					return name
						.split(' ')
						.map(part => part.charAt(0).toUpperCase())
						.join('')
				}

				const fetchImages = this.currentTeam.members
					.map(async (member: any) => {
						const memberInfo: {
							initials: string
							img: string | undefined
						} = {
							initials: getInitials(member.name),
							img: undefined
						}

						if (member.image) {
							const response = await fetchApiGetFileFromUrl(member.image)
							if (response) {
								memberInfo.img = response.base64
							}
						}
						return memberInfo
					})
				this.avatars = await Promise.all(fetchImages)
			}
		}
	}
})

import { defineStore } from 'pinia'

import type {
	IFetchApiCreateProduct,
	IFetchApiFindProduct,
	IFetchApiGetManufacturerList,
	IFetchApiProductAttributeList,
	IFetchApiProductList,
	IFetchApiProductTypeList,
	IFetchApiUpdateDBProduct,
	IFetchApiUpdateProjectProduct
} from '~/api'
import {
	fetchApiCreateProduct,
	fetchApiFindProduct,
	fetchApiGetManufacturerList,
	fetchApiProductAttributeList,
	fetchApiProductList,
	fetchApiProductTypeList,
	fetchApiUpdateDBProduct,
	fetchApiUpdateProjectProduct
} from '~/api'
import { ICPProductAdditionalAttrs } from '~/components'
import type { IProductsStoreTypes } from '~/stores'

export const useProductsStore = defineStore('useProductsStore', {
	state: (): IProductsStoreTypes => ({
		productList: [],
		attrsList: [],
		productTypeList: [],
		manufacturerList: []
	}),
	getters: {
		getAttrsList (state: IProductsStoreTypes) {
			return [{
				id: 0,
				name: ICPProductAdditionalAttrs.Type,
				formula_symbol: ''
			}, {
				id: 1,
				name: ICPProductAdditionalAttrs.Manufacturer,
				formula_symbol: ''
			}, {
				id: 2,
				name: ICPProductAdditionalAttrs.CertificateNumber,
				formula_symbol: ''
			}, ...state.attrsList]
		}
	},
	actions: {
		resetProductState () {
			this.productList = []
			this.attrsList = []
		},
		async fetchProductList (
			payload: IFetchApiProductList
		) {
			const response = await fetchApiProductList(payload)

			if (response && response.data) {
				this.productList = response.data
			}
		},
		async fetchProductAttributeList (
			payload: IFetchApiProductAttributeList
		) {
			const response = await fetchApiProductAttributeList(payload)

			if (response && response.data) {
				this.attrsList = response.data
			}
		},
		async fetchProductTypeList (
			payload: IFetchApiProductTypeList
		) {
			const response = await fetchApiProductTypeList(payload)

			if (response && response.data) {
				this.productTypeList = response.data
			}
		},
		async fetchManufacturerList (
			payload: IFetchApiGetManufacturerList
		) {
			const response = await fetchApiGetManufacturerList(payload)

			if (response) {
				this.manufacturerList = response
			}
		},
		async createProduct (
			payload: IFetchApiCreateProduct
		) {
			return await fetchApiCreateProduct(payload)
		},
		async updateProduct (
			payload: IFetchApiUpdateDBProduct
		) {
			return await fetchApiUpdateDBProduct(payload)
		},
		async findProduct (
			payload: IFetchApiFindProduct
		) {
			return await fetchApiFindProduct(payload)
		},
		async updateProjectProduct (payload: IFetchApiUpdateProjectProduct) {
			const response = await fetchApiUpdateProjectProduct(payload)

			if (response) {
				const product = this.productList.find(
					(item) => item.id === payload.id
				)

				if (product) {
					product.amount = payload.payload.amount
				}
			}
		}
	}
})

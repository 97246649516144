import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

import { SINGLE_PROJECT_ROUTES_NAMES } from './singleProjectTypes'

const breakpoints = useBreakpoints(breakpointsTailwind)

export function getSingleProjectRoutes (app?: App): RouteRecordRaw {
	return {
		path: '/project/:projectId',
		name: SINGLE_PROJECT_ROUTES_NAMES.SingleProject,
		component: () => import('~/pages/single-project/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		redirect (): { name: string } {
			const isGreaterThanMd = breakpoints.greater('xl')
			if (isGreaterThanMd.value) {
				return {
					name: SINGLE_PROJECT_ROUTES_NAMES.DocumentsChecklist
				}
			} else {
				return {
					name: SINGLE_PROJECT_ROUTES_NAMES.GeneralInformation
				}
			}
		},
		children: [
			{
				path: 'documents-checklist',
				name: SINGLE_PROJECT_ROUTES_NAMES.DocumentsChecklist,
				component:
          () => import('~/pages/single-project/tabs/documents-checklist.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'certificates',
				name: SINGLE_PROJECT_ROUTES_NAMES.Certificates,
				component:
					() => import('~/pages/single-project/tabs/certificate.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'project-settings',
				name: SINGLE_PROJECT_ROUTES_NAMES.ProjectSettings,
				component:
          () => import('~/pages/single-project/tabs/project-settings.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'billing-information',
				name: SINGLE_PROJECT_ROUTES_NAMES.BillingInformation,
				component:
          () => import('~/pages/single-project/tabs/billing-information.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'general-information',
				name: SINGLE_PROJECT_ROUTES_NAMES.GeneralInformation,
				component:
          () => import('~/pages/single-project/tabs/general-information.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'chat',
				name: SINGLE_PROJECT_ROUTES_NAMES.CHAT,
				component:
          () => import('~/pages/single-project/tabs/chat.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'activity-logs',
				name: SINGLE_PROJECT_ROUTES_NAMES.ActivityLogs,
				component:
          () => import('~/pages/single-project/tabs/activity-logs.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'offer-documents',
				name: SINGLE_PROJECT_ROUTES_NAMES.OfferDocuments,
				component:
          () => import('~/pages/single-project/tabs/offer-documents.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'reports',
				name: SINGLE_PROJECT_ROUTES_NAMES.Reports,
				component:
          () => import('~/pages/single-project/tabs/reports.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}

export const getSingleProjectInvoicePdfViewerRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/invoice/pdf-viewer/:file/:name/:id/',
		name: SINGLE_PROJECT_ROUTES_NAMES.PdfViewerInvoice,
		component:
      () => import('~/pages/single-project/invoice-pdf-viewer.vue'),
		beforeEnter: createAuthGuard(app as any),
		props: (route) => ({
			file: route.params.file,
			name: route.params.name,
			id: route.params.id,
			idProject: route.params.projectId
		})
	}
}

export const getSingleProjectOfferPdfViewerRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/offer/pdf-viewer/:file/:name/:id/',
		name: SINGLE_PROJECT_ROUTES_NAMES.PdfViewerOffer,
		component:
    () => import('~/pages/single-project/offer-pdf-viewer.vue'),
		beforeEnter: createAuthGuard(app as any),
		props: (route) => ({
			file: route.params.file,
			name: route.params.name,
			id: +route.params.id!,
			idProject: +route.params.projectId!
		})
	}
}

export const getSingleProjectCertificatePdfViewerRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/certificate/pdf-viewer/:file/:name/:certificateId/',
		name: SINGLE_PROJECT_ROUTES_NAMES.PdfViewerCertificate,
		component:
			() => import('~/pages/single-project/certificate-pdf-viewer.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

export const getSingleProjectReportPdfViewerRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/report/pdf-viewer/:file/:name/:id/',
		name: SINGLE_PROJECT_ROUTES_NAMES.PdfViewerReport,
		component:
    () => import('~/pages/single-project/report-pdf-viewer.vue'),
		beforeEnter: createAuthGuard(app as any),
		props: (route) => ({
			file: route.params.file,
			name: route.params.name,
			id: +route.params.id!,
			idProject: +route.params.projectId!
		})
	}
}

export const getSingleProjectProductPage = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/products',
		name: SINGLE_PROJECT_ROUTES_NAMES.Products,
		component:
      () => import('~/pages/single-project/products.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

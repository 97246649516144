export enum ECP_OFFER_DOCUMENTS_TABLE_KEYS {
  DocumentName = 'Dokumentname',
  Type = 'Typ',
  Service = 'Service',
  DateTime = 'Datum und Uhrzeit',
  PerformedBy = 'Aufgeführt von',
  Actions = 'Aktionen'
}

export interface ICPOfferDocumentsTableItem {
  [ECP_OFFER_DOCUMENTS_TABLE_KEYS.DocumentName]: string
  [ECP_OFFER_DOCUMENTS_TABLE_KEYS.Service]: string
  [ECP_OFFER_DOCUMENTS_TABLE_KEYS.DateTime]: string
  [ECP_OFFER_DOCUMENTS_TABLE_KEYS.PerformedBy]: string
  [ECP_OFFER_DOCUMENTS_TABLE_KEYS.Actions]: number
}

export interface ICPOfferDocumentsTableProps {
  data: ICPOfferDocumentsTableItem[]
  loading?: boolean
  disabled?: boolean
  noDataText?: string
}

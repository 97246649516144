import type { TCPTheme, ThemeProps } from './'

export const themePrefix: string = 'cp'
export const themeDivider: string = '-'

export const themeProps: ThemeProps = {
	colors: {
		main: {
			300: '#E66F04',
			200: '#FB851B',
			100: '#FB9941',
			50: '#FFF5ED'
		},
		accent: {
			300: '#0951B4',
			200: '#0B66E4',
			100: '#2E81F5',
			50: '#EBF3FD'
		},
		neutral: {
			700: '#19191F',
			600: '#2E333D',
			500: '#4C5A74',
			400: '#7B879E',
			300: '#98A6B8',
			200: '#C3CBD5',
			100: '#E2E6E9',
			50: '#F9FAFB',
			0: '#FFFFFF'
		},
		bg: {
			green: '#EFF7F0',
			red: '#FDEFEE'
		},
		base: {
			green: '#319F43',
			red: '#E33629'
		}
	}
}

const renderColors = (
	obj: TCPTheme,
	parentKey: string = ''
): TCPTheme => {
	const result: TCPTheme = {}

	for (const key in obj) {
		const currentKey = parentKey
			? `${themePrefix + themeDivider + parentKey + themeDivider + key}`
			: key

		if (typeof obj[key] === 'object' && obj[key] !== null) {
			const nestedResult = renderColors(obj[key] as TCPTheme, currentKey)
			Object.assign(result, nestedResult)
		} else {
			result[currentKey] = obj[key]
		}
	}

	return result
}

export const CPTheme = {
	colors: renderColors(themeProps.colors),
	breakpoints: {},
	font: {},
	defaultFont: {},
	stylesheets: []
}

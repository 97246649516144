/* eslint-disable max-len */
import { inject } from 'vue'

export type CPQueryType = Record<string, string | string[]>

export const QUERY_KEY = 'globalQuery'

export const useGlobalQuery = (defaultValue: CPQueryType | null = null): CPQueryType | null => {
	return inject(QUERY_KEY, defaultValue)
}

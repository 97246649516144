export enum DemoRoutesNamesEnum {
  sidebar = 'sidebar',
  overlay = 'overlay',
  colors = 'colors',
  avatar = 'avatar',
  inputCalendar = 'input-calendar',
  fileViewer = 'file-viewer',
  actionItem = 'action-item',
  buttons = 'buttons',
  controls = 'controls',
  dialog = 'dialog',
  dropdown = 'dropdown',
  icons = 'icons',
  inputs = 'inputs',
  link = 'link',
  progressBar = 'progress-bar',
  segmentControl = 'segment-control',
  select = 'select',
  snackbar = 'snackbar',
  stepper = 'stepper',
  tabNavigation = 'tab-navigation',
  tag = 'tag',
  tooltip = 'tooltip',
  fileUpload = 'file-upload',
  alert = 'alert',
  textEditor = 'text-editor',
  typography = 'typography',
  breadcrumbs = 'breadcrumbs',
  counter = 'counter',
  radioBlock = 'radio-block',
  table = 'table',
  accordion = 'accordion',
  infinityScroll = 'infinity-scroll',
  multiInput = 'multi-input',
  multiSelect = 'multi-select',
  passwordField = 'password-field'
}

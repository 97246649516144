export enum ECP_MEMBERS_TABLE_KEYS {
  Name = 'Name',
  Email = 'Email',
  Status = 'Status',
  Projects = 'Projekte',
  Actions = 'Aktionen'
}

export enum ECP_MEMBER_STATUSES {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Invited = 'INVITED',
  InvitationExpired = 'INVITATION_EXPIRED'
}

export interface ICPTableMembersItemTypes {
  [ECP_MEMBERS_TABLE_KEYS.Name]: string
  [ECP_MEMBERS_TABLE_KEYS.Email]: string
  [ECP_MEMBERS_TABLE_KEYS.Status]: string
  [ECP_MEMBERS_TABLE_KEYS.Projects]: number | string
  [ECP_MEMBERS_TABLE_KEYS.Actions]: {
    id: number
    status: ECP_MEMBER_STATUSES
  }
}

export interface ICPTableMembersPropTypes {
  modelValue: ICPTableMembersItemTypes[]
  loading?: boolean
  canEdit?: boolean
}

<script lang="ts">
import {
	computed,
	defineComponent,
	getCurrentInstance,
	h,
	ref,
	resolveComponent
} from 'vue'
import type { ConcreteComponent, PropType } from 'vue'

import { useLink } from '~/composables'
import { type CPQueryType, useGlobalQuery } from '~/utils'

import { CP_LINK_TARGET } from './index'
import { type CPLinkPropsTypes } from './index'

export default defineComponent({
	inheritAttrs: false,

	props: {
		to: {
			type: [String, Object] as PropType<CPLinkPropsTypes['to']>
		},

		query: {
			type: Object as PropType<CPLinkPropsTypes['query']>,
			default: undefined,
			required: false
		},

		wrapper: {
			type: Boolean as PropType<CPLinkPropsTypes['wrapper']>,
			default: false
		},

		target: {
			type: String as PropType<CPLinkPropsTypes['target']>,
			required: false
		},

		name: {
			type: String as PropType<CPLinkPropsTypes['name']>,
			default: 'link',
			required: false
		}
	},

	setup (props, { slots, attrs }) {
		const instance = getCurrentInstance()

		const hasRouter = computed(() => {
			const components = instance?.appContext.components
			return !!components?.RouterLink
		})

		const isExternal = computed(() => useLink(props.to).isExternal())

		const isMailToOrTel = computed(() => {
			if (typeof props.to === 'string') {
				return props.to.startsWith('mailto:') || props.to.startsWith('tel:')
			}
			return false
		})

		const isTargetValid = computed(() => {
			if (!props.target) {
				return false
			}

			return Object.values(CP_LINK_TARGET).includes(props.target)
		})

		const to = typeof props.to === 'string' ? props.to : props.to?.path
		const query = ref<CPQueryType | null>(null)

		const { appendQueryParameter } = useLink(to)
		const isExternalLink = to && isExternal.value

		if (isExternalLink) {
			query.value = useGlobalQuery(props.query)
		}

		const href = computed<string>(() => {
			if (isExternalLink && query.value) {
				return appendQueryParameter(query.value!)
			} else if (typeof props.to === 'string') {
				return props.to
			}

			return props.to?.path || ''
		})

		if (!isExternal.value && !isMailToOrTel.value && hasRouter) {
			return () => {
				const component = resolveComponent('RouterLink')
				let componentProps = {
					...attrs,
					class: [
						'cp-link',
						attrs.class || ''
					],
					'aria-label': props.name,
					to: props.to,
					target: props.target
				}

				if (componentProps.target === '_blank') {
					componentProps = Object.assign(
						componentProps,
						{ rel: 'noopener' }
					)
				}

				return h(component as ConcreteComponent, componentProps, {
					default: (all: any) => {
						return slots.default?.(all)
					}
				})
			}
		}

		const tag = computed(() => props.wrapper ? 'div' : 'a')
		let additionalAttrs = {}

		if (props.wrapper) {
			additionalAttrs = {
				onClick: () => {
					if (!props.target) {
						window.location.href = href.value
						return
					}

					if (isTargetValid.value) {
						window.open(href.value, props.target)
					}
				}
			}
		} else {
			additionalAttrs = {
				href: href.value
			}

			if (isTargetValid.value) {
				additionalAttrs = Object.assign(
					additionalAttrs,
					{ target: props.target }
				)

				if (props.target === '_blank') {
					additionalAttrs = Object.assign(
						additionalAttrs,
						{ rel: 'noopener' }
					)
				}
			}
		}

		return () => {
			return h(tag.value, {
				...attrs,
				...additionalAttrs,
				'aria-label': props.name,
				class: [
					'cp-link',
					attrs.class || ''
				],
				disabled: attrs.disabled || undefined
			}, slots.default?.())
		}
	}
})
</script>

<style lang="scss">
	.cp-link {
		@apply
			decoration-none
			cursor-pointer
		;
	}
</style>

export enum ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS {
  Description = 'Beschreibung',
  Information = 'Informationen',
  LastUpdate = 'Letzte Aktualisierung',
}

export interface ICPProjectControllersTableRow {
  [ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Description]: string
  [ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Information]: string
  [ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.LastUpdate]: string
}

export interface ICPProjectControllersTableProps {
  data: ICPProjectControllersTableRow[]
  loading?: boolean
}

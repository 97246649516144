import type { VueElement } from 'vue'

import type { LAppLayoutsEnum } from '~/layouts/LApp'

export enum RoutesNamesEnum {
  home = 'home',
  login = 'login',
  notFound = 'not-found',
  dataProtection = 'data-protection',
  documentChecking = 'document-checking',
}

declare module 'vue-router' {
  interface RouteMeta {
    layout?: LAppLayoutsEnum
    layoutComponent?: VueElement
  }
}

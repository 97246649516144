import { createVuetify } from 'vuetify'

import { CPTheme } from '../utils'

import 'vuetify/styles'

export default createVuetify({
	theme: {
		defaultTheme: 'cpTheme',
		themes: {
			cpTheme: {
				dark: false,
				colors: CPTheme.colors as any
			}
		}
	}
})

import type { ErrorObject } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export const getErrors = (errors: ErrorObject[]): string[] => (
	errors.map((e: ErrorObject) => e.$message as string)
)

export const requiredDe = () => {
	return helpers.withMessage('Dieses Feld darf nicht leer sein', required)
}

export const isNumericOrDecimal = helpers.withMessage(
	'Ungültiges Zahlenformat. Verwenden Sie einen numerischen Wert, einen Punkt oder ein Komma als Dezimaltrennzeichen.',
	(value: string | undefined) => {
		if (!value) return true // Allow empty values (required can handle non-empty validation)

		// Allow integers, decimals with dot, and decimals with comma
		const commaToDot = value.replace(',', '.') // Normalize commas to dots for validation
		return !isNaN(Number(commaToDot)) && /^-?\d+(\.\d+)?$/.test(commaToDot)
	}
)

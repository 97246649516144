<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useDisplay } from 'vuetify'

import { useElementSize } from '@vueuse/core'

const appendRef = ref()
const { width: displayWidth } = useDisplay()
const { width: appendRefWidth } = useElementSize(appendRef)

const computedPrependMaxWidth = computed(() => {
	return displayWidth.value >= 1279.99
		? `max-width: calc(100% - ${appendRefWidth.value + 24}px)`
		: undefined
})
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <div
    :class="[
      'cp-heading',
      $attrs.class
    ]"
  >
    <div
      v-if="$slots.breadcrumbs"
      class="cp-heading__breadcrumbs"
    >
      <slot name="breadcrumbs" />
    </div>

    <div class="cp-heading__inner">
      <div
        v-if="$slots.prepend"
        class="cp-heading__prepend"
        :style="computedPrependMaxWidth"
      >
        <slot name="prepend" />
      </div>

      <div
        v-if="$slots.append"
        ref="appendRef"
        class="cp-heading__append"
      >
        <slot name="append" />
      </div>
    </div>

    <div
      v-if="$slots.after"
      class="cp-heading__after"
    >
      <slot name="after" />
    </div>
  </div>
</template>

<style lang="scss">
  .cp-heading {
    @apply
      text-sm
      font-normal
      py-8
    ;

    &__breadcrumbs {
      @apply
        mb-2
      ;
    }

    &__append {
      @apply
        <xl:mt-6
      ;
    }

    @screen xl {
      &__inner {
        @apply
          flex
          gap-6
        ;
      }

      &__append {
        @apply
          flex-shrink-0
          ml-auto
        ;
      }
    }
  }
</style>

import type { components } from 'schema'

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiProductList {
	token: IFetchApiProps['token']
  projectId?: number
	params?: {
		page?: string | number
		search?: string | number
		manufacturer_id?: string | number
		type_id?: string | number
    type?: string
	}
}

export interface IFetchApiProductAttributeList {
	token: IFetchApiProps['token']
	params?: {
		page?: string | number
		type?: 'CONTROLLER' | 'PROTECTOR' | 'PRODUCT'
	}
}

export interface IFetchApiProductTypeList {
	token: IFetchApiProps['token']
	params?: {
		page?: string | number
    return_all?: boolean
	}
}

export interface IFetchApiGetManufacturerList {
	token: IFetchApiProps['token']
	params?: {
		page?: number
		return_all?: boolean
	}
}

export interface IFetchApiUpdateDBProduct {
	id: string | number
	token: IFetchApiProps['token']
	payload: components['schemas']['UpdateProduct']
}

export interface IFetchApiCreateProduct {
	token: IFetchApiProps['token']
  projectId?: number
	payload: components['schemas']['CreateProduct']
}

export interface IFetchApiFindProduct {
  token: IFetchApiProps['token']
  projectId: number
  payload: any
}

export interface IFetchApiDeleteProduct {
  token: IFetchApiProps['token']
  projectId: number
  id: number
  params: {
    type: string
  }
}

export interface IFetchApiUpdateAttributeValue {
  token: IFetchApiProps['token']
  projectId: number
  id: number
  payload: {
    attributes: {
      id: number
      value: string
    }[]
    amount: number
  }
}

export interface IFetchApiProductImport {
	token: IFetchApiProps['token']
	params?: {
		type?: string
		name?: string
	}
	payload: {
		name: string
		base64: string
	}
}

export interface IFetchApiUpdateProjectProduct {
  token: IFetchApiProps['token']
  projectId: number
  id: number
  payload: components['schemas']['UpdateProductProject']
}

export const fetchApiProductList = async (
	payload: IFetchApiProductList
) => {
	const url = payload.projectId
		? `/project/${payload.projectId}/product/list`
		: '/product/list'
	return await fetchApi({
		url,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProductList,
		params: {
			...payload.params,
			return_all: true
		}
	})
}

export const fetchApiProductAttributeList = async (
	payload: IFetchApiProductAttributeList
) => {
	return await fetchApi({
		url: 'product/attribute/list?return_all=true',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProductAttributeList,
		params: payload.params
	})
}

export const fetchApiProductTypeList = async (
	payload: IFetchApiProductTypeList
) => {
	return await fetchApi({
		url: '/product/type/list?return_all=true',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProductTypeList
	})
}

export const fetchApiGetManufacturerList = async (
	payload: IFetchApiGetManufacturerList
) => {
	const response = await fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ManufacturerList,
		url: '/product/manufactuer/list',
		params: {
			...payload.params,
			return_all: true
		}
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiCreateProduct = async (
	{ payload, projectId, token } : IFetchApiCreateProduct
) => {
	const url = projectId ? `/project/${projectId}/product` : '/product'
	await fetchApi({
		url,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ProductCreate,
		token,
		payload
	})
}

export const fetchApiUpdateDBProduct = async (
	{ payload, id, token } : IFetchApiUpdateDBProduct
) => {
	await fetchApi({
		url: `/product/${id}/update`,
		method: FETCH_API_METHODS.Put,
		token,
		pendingKey: STATE_STORE_KEYS.ProductDBUpdate,
		payload
	})
}

export const fetchApiFindProduct = (
	{ payload, projectId, token } : IFetchApiFindProduct
) => {
	return fetchApi({
		url: `/project/${projectId}/product/find`,
		method: FETCH_API_METHODS.Post,
		token,
		pendingKey: STATE_STORE_KEYS.ProductFind,
		payload
	})
}

export const fetchApiDeleteProduct = (
	{ token, projectId, id, params }: IFetchApiDeleteProduct
) => {
	return fetchApi({
		url: `/project/${projectId}/product/${id}/delete`,
		method: FETCH_API_METHODS.Delete,
		token,
		pendingKey: STATE_STORE_KEYS.ProductDelete,
		params
	})
}

export const fetchApiUpdateAttributeValue = (
	{ token, projectId, id, payload }: IFetchApiUpdateAttributeValue
) => {
	return fetchApi({
		url: `/project/${projectId}/product/${id}/update`,
		method: FETCH_API_METHODS.Put,
		token,
		pendingKey: STATE_STORE_KEYS.ProductDBUpdate,
		payload
	})
}

export const fetchApiProductImport = (
	payload: IFetchApiProductImport
) => {
	return fetchApi({
		url: '/product/import',
		method: FETCH_API_METHODS.Post,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.ProductImport,
		payload: payload.payload,
		params: payload.params
	})
}

export const fetchApiUpdateProjectProduct = (
	payload: IFetchApiUpdateProjectProduct
) => {
	return fetchApi({
		url: `/project/${payload.projectId}/product/${payload.id}/update`,
		method: FETCH_API_METHODS.Put,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.ProductUpdate,
		payload: payload.payload
	})
}

import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { MemberRoutesNamesEnum } from '.'

export const getMemberRouter = (app?: App): RouteRecordRaw => {
	return 		{
		path: '/member/:id',
		name: MemberRoutesNamesEnum.member,
		component: () => import('~/pages/member/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		children: [
			{
				path: 'member-information',
				name: MemberRoutesNamesEnum.memberInformation,
				component: () => import('~/pages/member/member-information.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'member-projects',
				name: MemberRoutesNamesEnum.memberProjects,
				component: () => import('~/pages/member/member-projects.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'member-settings',
				name: MemberRoutesNamesEnum.memberSettings,
				component: () => import('~/pages/member/member-settings.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}

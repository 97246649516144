export enum ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS {
  Id = 'id',
  ChecklistName = 'Name der Checklisten',
  UsedInProjects = 'Verwendet in Projekten',
  ListItems = 'Elemente auflisten',
  Questions = 'Fragen',
  LastUpdate = 'Letzte Aktualisierung',
}

export interface ICPCompanyChecklistsTableRow {
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.Id]: number
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.ChecklistName]: string
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.UsedInProjects]: number
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.ListItems]: number
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.Questions]: number
  [ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.LastUpdate]: string
}

export interface ICPCompanyChecklistsTableProps {
  data: ICPCompanyChecklistsTableRow[]
  loading?: boolean
}

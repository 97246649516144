import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { ClientRoutes } from './'

export const getClientRoutes = (app?: App): RouteRecordRaw => ({
	path: '/clients/:id',
	name: ClientRoutes.Client,
	component: () => import('~/pages/client/index.vue'),
	beforeEnter: createAuthGuard(app as any),
	redirect: () => ({ name: ClientRoutes.ClientPersonalInformation }),
	children: [
		{
			path: 'personal-information',
			name: ClientRoutes.ClientPersonalInformation,
			component: () => import('~/pages/client/personal-information.vue'),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'projects',
			name: ClientRoutes.ClientProjects,
			component: () => import('~/pages/client/projects.vue'),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'settings',
			name: ClientRoutes.ClientSettings,
			component: () => import('~/pages/client/settings.vue'),
			beforeEnter: createAuthGuard(app as any)
		}
	]
})

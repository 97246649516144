export enum CP_REVIEW_CHECKLIST_TABLE_COLUMNS {
  ReviewChecklistsName = 'Checklisten überprüfen name',
  PerformedBy = 'Durchgeführt von',
  Date = 'Datum und Uhrzeit'
}

export interface ICPReviewChecklistItem {
  [CP_REVIEW_CHECKLIST_TABLE_COLUMNS.ReviewChecklistsName]: string
  [CP_REVIEW_CHECKLIST_TABLE_COLUMNS.Date]: string
}

export interface ICPReviewChecklistTableDataProps {
  data: ICPReviewChecklistItem[]
  isLoading?: boolean
}

import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { NotificationsRouteNamesEnum } from '.'

export const getNotificationsRoutes = (app?: App): RouteRecordRaw => {
	return {
		path: '/notifications',
		name: NotificationsRouteNamesEnum.Notifications,
		component: () => import('~/pages/notifications.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

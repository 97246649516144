import type { RouteRecordRaw } from 'vue-router'

import { LAppLayoutsEnum } from '~/layouts'
import {
	emailVerificationMiddleWare,
	personalDetailsMiddleWare
} from '~/middleware'
import { AccountRoutesNamesEnum } from '~/router/modules/account/accountTypes'

export const getAccountRoutes = (): RouteRecordRaw => {
	return {
		path: '/account',
		name: AccountRoutesNamesEnum.account,
		component: () => import('~/pages/account/index.vue'),
		meta: {
			layout: LAppLayoutsEnum.signUp
		},
		children: [{
			path: '',
			name: AccountRoutesNamesEnum.accountCreate,
			component: () => import('~/pages/account/create.vue'),
			meta: {
				title: 'Konto erstellen'
			}
		},
		{
			path: 'personal-info',
			name: AccountRoutesNamesEnum.personalInfo,
			component: () => import('~/pages/account/personal-info.vue'),
			beforeEnter: personalDetailsMiddleWare,
			meta: {
				title: 'Persönliche Informationen',
				subTitle: 'Geben Sie unten Ihre persönlichen Daten ein, um die Erstellung Ihres Kontos abzuschließen.',
				isBack: true
			}
		},
		{
			path: 'email-verification',
			name: AccountRoutesNamesEnum.emailVerification,
			component: () => import('~/pages/account/email-verification.vue'),
			beforeEnter: emailVerificationMiddleWare,
			meta: {
				isBack: true
			}
		}]
	}
}

import { defineStore } from 'pinia'

import type {
	IFetchAPIProtectorCompanyList,
	IFetchApiProtectorList,
	IFetchApiProtectorTypeList
} from '~/api/apiProtectors'
import {
	fetchAPIProtectorCompanyList,
	fetchApiProtectorList,
	fetchApiProtectorTypeList
} from '~/api/apiProtectors'
import type { IProtectorsStoreTypes } from '~/stores'

export const useProtectorsStore = defineStore('protectors', {
	state: (): IProtectorsStoreTypes => ({
		protectorsList: [],
		protectorTypeList: [],
		protectorsCompanyList: []
	}),
	actions: {
		async fetchProtectorsList (payload: IFetchApiProtectorList) {
			const response = await fetchApiProtectorList({
				...payload,
				params: {
					return_all: true
				}
			})

			if (response) {
				this.protectorsList = response
			}
		},

		async fetchProtectorTypeList (payload: IFetchApiProtectorTypeList) {
			const response = await fetchApiProtectorTypeList({
				...payload
			})

			if (response) {
				this.protectorTypeList = response
			}
		},

		async fetchProtectorsCompanyList (
			payload: IFetchAPIProtectorCompanyList
		) {
			const response = await fetchAPIProtectorCompanyList(payload)

			if (response) {
				this.protectorsCompanyList = response
			}
		}
	}
})

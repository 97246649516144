import { defineStore } from 'pinia'

import type {
	IFetchApiGetNotifications,
	IFetchApiReadNotification
} from '~/api'
import { fetchApiGetNotifications, fetchApiReadNotification } from '~/api'
import type {
	INotificationsCountData,
	INotificationsStoreTypes
} from '~/stores'
import { NotificationTypeEnum } from '~/stores'

export const useNotificationsStore = defineStore('notificationsStore', {
	state: (): INotificationsStoreTypes => ({
		currentPage: 1,
		isPagination: false,
		notifications: [],
		notificationCount: {
			ALL: 0,
			PROJECT: 0,
			LEGAL: 0,
			BILLING: 0,
			OPERATIONAL: 0
		}
	}),
	actions: {
		resetPagination () {
			this.currentPage = 1
			this.isPagination = false
			this.notifications = []
		},

		async getNotificationsCount (token: string) {
			const request = Object.keys(this.notificationCount).map((type) => {
				return fetchApiGetNotifications({
					token,
					forceFetch: true,
					params: {
						is_seen: false,
						type: type === NotificationTypeEnum.All
							? undefined
							: type
					}
				})
			})

			const responses = await Promise.all(request)

			if (responses) {
				responses.forEach((response, index) => {
					const key = Object.keys(this.notificationCount)[index] as keyof INotificationsCountData
					this.notificationCount[key] = response?.count ?? 0
				})
			}
		},

		async getNotificationList (payload: IFetchApiGetNotifications) {
			const response = await fetchApiGetNotifications({
				...payload,
				params: {
					...payload.params,
					page: this.currentPage
				}
			})
			if (response?.results) {
				this.currentPage = Number(response.next) ??
						(Number(response.previous) + 1) ??
						1
				this.notifications?.push(...response.results)
				this.isPagination = !!response.next
			}
		},

		async readNotification (payload: IFetchApiReadNotification) {
			const response = await fetchApiReadNotification(payload)

			if (response) {
				const item = this.notifications.find((item) => item.id === payload.id)
				if (item && !item.is_seen) {
					item.is_seen = true
					this.notificationCount.ALL -= 1
					this.notificationCount[item.type] -= 1
				}
			}
		}
	}
})

import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import Home from '~/pages/index.vue'
import { RoutesNamesEnum } from '~/router'
import {
	HomeRoutesNamesEnum
} from '~/router/modules/home/homeTypes'

export const getHomeRoutes = (app?: App): RouteRecordRaw => {
	return {
		path: '/',
		name: RoutesNamesEnum.home,
		component: Home,
		beforeEnter: createAuthGuard(app as any),
		children: [
			{
				path: '/',
				name: HomeRoutesNamesEnum.homeProjects,
				component: () => import('~/pages/home/home-projects.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'drafts',
				name: HomeRoutesNamesEnum.drafts,
				component: () => import('~/pages/home/drafts.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'templates',
				name: HomeRoutesNamesEnum.templates,
				component: () => import('~/pages/home/templates.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'clients',
				name: HomeRoutesNamesEnum.clients,
				component: () => import('~/pages/home/clients.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'chats',
				name: HomeRoutesNamesEnum.chats,
				component: () => import('~/pages/home/chats.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}

import type { IFetchApiProps } from '~/api'
import { FETCH_API_METHODS, fetchApi } from '~/api'
import { STATE_STORE_KEYS } from '~/types'

export interface IFetchApiActivityLogList {
  token: IFetchApiProps['token']
  id: string | number
	params?: {
		page?: string | number
		search?: string | number
		date_from?: string | number | Date
		done_by?: string | number
		date_to?: string | number | Date
	}
}

export const fetchApiActivityLogList = async (
	payload: IFetchApiActivityLogList
) => {
	return await fetchApi({
		url: `project/${payload.id}/activity-log/list`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ActivityLogList,
		params: payload.params
	})
}

export interface IFetchApiActivityLogAllList {
	token: IFetchApiProps['token']
	params?: {
		page?: string | number
		search?: string | number
		date_from?: string | number | Date
		done_by?: string | number
		date_to?: string | number | Date
	}
}

export const fetchApiActivityLogAllList = async (
	payload: IFetchApiActivityLogAllList
) => {
	return await fetchApi({
		url: '/project/activity-log/list',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ActivityLogAllList,
		params: payload.params
	})
}

export interface IFetchApiActivityLogUserAllList {
	token: IFetchApiProps['token']
	params: {
		return_all?: boolean
	}
}

export const fetchApiActivityLogUserAllList = async (
	payload: IFetchApiActivityLogUserAllList
) => {
	return await fetchApi({
		url: '/project/activity-log/user/list',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ActivityLogUserAllList,
		params: payload.params
	})
}

export interface IFetchApiActivityLogUserList {
	id: string | number
	token: IFetchApiProps['token']
}

export const fetchApiActivityLogUserList = async (
	payload: IFetchApiActivityLogUserList
) => {
	return await fetchApi({
		url: `project/${payload.id}/activity-log/user/list?return_all=true`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ActivityLogUserList
	})
}

import { defineStore } from 'pinia'

import type {
	IFetchApiProjectsIds,
	IFetchApiProps
} from '~/api'
import {
	FETCH_API_METHODS,
	fetchApi,
	fetchApiGetProjectAuthors, fetchApiGetProjectCount,
	fetchApiGetProjectManagers, fetchApiProjectsIds,
	httpClient
} from '~/api'
import { STATE_STORE_KEYS } from '~/types'

import type { IProjectsStoreTypes } from '.'

export const useProjectsStore = defineStore(
	'projectsStore', {
		state: (): IProjectsStoreTypes => ({
			projectCount: {},
			list: [],
			projectIds: {},
			selectedProjectsId: [],
			selectedPhase: 'all',
			currentPage: 1,
			pageCount: undefined,
			isPagination: false,
			managerList: [],
			authorList: []
		}),
		actions: {
			resetProjectsCurrentPage () {
				this.currentPage = 1
			},
			resetProjectsList () {
				this.list = []
			},
			resetProjectsPagination () {
				this.isPagination = true
			},
			resetProjectsGetListState () {
				this.resetProjectsCurrentPage()
				this.resetProjectsList()
				this.resetProjectsPagination()
			},
			async fetchExport (
				token: string = '',
				selectedProjectsId: IProjectsStoreTypes['selectedProjectsId']
			) {
				const response = await fetchApi({
					method: FETCH_API_METHODS.Post,
					url: '/project/list/export',
					payload: {
						project_ids: [...selectedProjectsId]
					},
					pendingKey: STATE_STORE_KEYS.Projects,
					token
				})

				if (response && response.data) {
					const url = response.data.url

					try {
						const response = await httpClient.get(url,
							{ responseType: 'blob' }
						)

						const blob = response.data
						const blobUrl = window.URL.createObjectURL(blob)

						const link = document.createElement('a')

						link.href = blobUrl
						link.download = 'cp-82-projects-export.csv'

						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link)

						window.URL.revokeObjectURL(blobUrl)
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error('Error downloading CSV:', error)
					}
				}
			},
			fetchProjects: async function (token: string = '', params?: any) {
				let queryString = ''

				if (params?.manager_id?.length) {
					queryString = `&${params.manager_id.map((obj: any) => `manager_id=${encodeURIComponent(obj.value)}`).join('&')}`
				}

				const response = await fetchApi({
					method: FETCH_API_METHODS.Get,
					url: `/project/list?page=${this.currentPage}${queryString}`,
					pendingKey: STATE_STORE_KEYS.Projects,
					token,
					params: {
						search: params?.search,
						phase: params?.phase,
						author: params?.author,
						is_archived: params?.is_archived,
						is_blocked: params?.is_blocked,
						is_draft: params?.is_draft,
						phase_type: params?.phase_type,
						ordering: params?.ordering
					}
				})

				if (response && response.data) {
					this.pageCount = response.data.count
					this.currentPage = response.data.next ??
						(response.data.previous + 1) ??
						1
					this.list?.push(...response.data.results)
					this.isPagination = !!response.data.next
				}
			},
			async fetchProjectsManagers (
				payload: {
					token: IFetchApiProps['token']
				}
			) {
				if (this.managerList.length) {
					return
				}

				const response = await fetchApiGetProjectManagers(payload)

				if (response) {
					this.managerList = response
				}
			},
			async fetchProjectsAuthors (
				payload: {
					token: IFetchApiProps['token']
				}
			) {
				const response = await fetchApiGetProjectAuthors(payload)

				if (response) {
					this.authorList = response
				}
			},
			async fetchGetProjectCount (
				payload: {
					token: IFetchApiProps['token']
				}
			) {
				const response = await fetchApiGetProjectCount(payload)

				if (response) {
					this.projectCount = response
				}
			},
			async fetchProjectIds (
				payload: IFetchApiProjectsIds
			) {
				const response = await fetchApiProjectsIds(payload)

				if (response && response.data) {
					this.projectIds = response.data
				}
			}
		}
	}
)

import type { Auth0Plugin } from '@auth0/auth0-vue'
import { createAuth0 } from '@auth0/auth0-vue'

const createAuth = (): Auth0Plugin => {
	return createAuth0({
		useRefreshTokens: true,
		cacheLocation: 'localstorage',
		domain: import.meta.env.VITE_AUTH0_DOMAIN,
		clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
		authorizationParams: {
			redirect_uri: window.location.origin,
			audience: import.meta.env.VITE_AUTH0_AUDIENCE
		}
	})
}
export default createAuth

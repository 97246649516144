<script lang="ts" setup>
import { computed, ref } from 'vue'

import {
	CPCounter,
	CPLink,
	CPSpinner,
	CPSvgIcon
} from '~/components/base'
import { CPTypography } from '~/components/layout'
import { useLink } from '~/composables'
import { type CPQueryType, useGlobalQuery } from '~/utils'

import type { CPButtonPropsTypes } from './index.ts'

const props = withDefaults(defineProps<CPButtonPropsTypes>(), {
	tag: 'button',
	disabled: false,
	name: 'button',
	variant: 'flat',
	color: 'main',
	type: 'button',
	rounded: false,
	attributes: () => ({}),
	loading: false
})

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
  (e: 'mouseover', value: MouseEvent): void
}>()

const query = ref<CPQueryType | null>(null)
const buttonRef = ref<
  InstanceType<typeof HTMLButtonElement | typeof HTMLAnchorElement>
  | null
>(null)

const computedTag = computed(() => {
	if (props.tag === 'a' || (props.to || props.href)) {
		return CPLink
	}

	return 'button'
})

const computedDisabled = computed(() => {
	if (props.tag === 'a' || (props.to || props.href)) {
		return undefined
	}

	return props.disabled
})

if (props.href) {
	query.value = useGlobalQuery()
}

const computedType = computed(() => {
	if (props.href || props.to) {
		return undefined
	}

	return props.type
})

const computedColor = computed(() => {
	if (props.color) {
		return `cp-button--color-${props.color}`
	}
	return undefined
})

const onMouseover = (event: MouseEvent): void => {
	emit('mouseover', event)
}

const computedHref = computed(() => {
	if (props.to) {
		return props.to
	}

	if (query.value && props.href) {
		return useLink(props.href).appendQueryParameter(query.value)
	}

	return props.href
})

const blur = (): void => {
	if (buttonRef.value) {
		buttonRef.value.blur()
	}
}

const focus = (): void => {
	if (buttonRef.value) {
		buttonRef.value.focus()
	}
}

const onClick = (evt: MouseEvent): void => {
	emit('click', evt)
}

defineExpose({
	blur,
	focus,
	buttonRef
})
</script>

<script lang="ts">
export default {
	inheritAttrs: false
}
</script>

<template>
  <Component
    ref="buttonRef"
    v-bind="attributes"
    :is="computedTag"
    :disabled="computedDisabled"
    :to="computedHref"
    :type="computedType"
    :aria-label="name"
    :class="[
      $attrs.class,
      'cp-button',
      computedColor,
      `cp-button--variant-${variant}`,
      {
        'cp-button--square': square,
        'cp-button--rounded': rounded,
        'cp-button--shadow': isShadow,
        'cp-button--loading': loading,
        'cp-button--disabled': disabled,
        'cp-button--size-sm': size === 'sm'
      },
    ]"
    @click="onClick"
    @mouseover="onMouseover"
  >
    <span v-if="loading" class="cp-button__loader">
        <CPSpinner :color="variant" />
    </span>
    <span class="cp-button__content">
      <CPSvgIcon
        v-if="iconPrepend"
        :name="iconPrepend"
        :size="iconSize"
        :class="`text-cp-${iconColor}`"
      />

      <CPTypography
        v-if="!rounded && !square"
        variant="button1"
        class="cp-button__content-main"
      >
        <slot />
      </CPTypography>

      <CPSvgIcon
        v-if="iconAppend"
        :name="iconAppend"
        :size="iconSize"
        :class="`text-cp-${iconColor}`"
      />
    </span>
    <CPCounter
      v-if="counter && counter >= 1"
      :value="counter"
      class="cp-button__counter"
    />
  </Component>
</template>

<style lang="scss">
.cp-button {
  @apply
    relative
    py-3
    rounded-1
    font-medium
    text-[1rem]
    text-default
    cursor-pointer
    bg-white
    inline-flex
    justify-center
    items-center
    transition
  ;

  &--disabled {
    @apply
      cursor-not-allowed
      pointer-events-none
    ;
  }

  &--loading {
    @apply
      pointer-events-none
    ;

    .cp-button__content {
      @apply
        opacity-0
      ;
    }
  }

  &__content {
    @apply
      inline-flex
      items-center
      justify-center
    ;

    &-main {
      @apply
        inline-flex
        items-center
        justify-center
        mx-6
        last-child:pl-2
        not-last:mr-2
      ;
    }

    .cp-svg-icon {
      &:not(:last-child):not(:only-child) {
        @apply ml-3;
      }

      &:last-child:not(:only-child) {
        @apply mr-3;
      }

      & + .cp-button__content-main {
        @apply
          ml-2
        ;
      }
    }

    &--icon {
      &-prepend {
        @apply
          pl-4
        ;
      }
    }
  }

  &__loader {
    @apply
      absolute
      top-0
      left-0
      w-full
      h-full
      flex
      justify-center
      items-center
    ;
  }

  &__counter {
    @apply
      absolute
      -top-0.5
      -right-0.5
    ;
  }

  &--shadow {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
  }

  &--rounded {
    @apply
      rounded-full
      p-0
      w-12
      h-12
    ;

    .cp-button__content {
      @apply
        px-0
      ;
    }
  }

  &--square {
    @apply
      p-0
      w-12
      h-12
    ;

    .cp-button__content {
      @apply
        px-0
      ;
    }
  }

  &--variant {
    &-flat {
      &.cp-button {
        &--color {
          &-main {
            @apply
              bg-cp-main-200
              hover:bg-cp-main-100
              active:bg-cp-main-300
            ;

            .cp-button__content {
              @apply
              text-white
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-300
              ;
            }
          }

          &-accent {
            @apply
              bg-transparent
              hover:bg-cp-accent-50
            ;

            .cp-button__content {
              @apply
              text-cp-accent-200
              ;
            }

            &.cp-button--loading {
              @apply
              bg-cp-accent-50
              ;

              .cp-loading-spinner {
                @apply
                  border-cp-accent-200
                ;
              }
            }

            &:active {
              .cp-button__content {
                @apply
                text-cp-accent-300
                ;
              }
            }

            &.cp-button--disabled {
              .cp-button__content {
                @apply
                  text-cp-neutral-300
                ;
              }
            }
          }

          &-neutral {
            @apply
              bg-cp-neutral-600
              text-white
              border
              border-solid
              border-cp-neutral-500
            ;

            .cp-button__content {
              @apply
              text-white
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-500
              ;
            }
          }

          &-red {
            @apply
              bg-cp-base-red
              text-white
              border
              border-solid
              border-cp-base-red
            ;

            .cp-button__content {
              @apply
              text-white
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-500
              ;
            }
          }
        }
      }
    }

    &-outlined {
      &.cp-button {
        &--color {
          &-neutral {
            @apply
              border
              border-solid
              border-cp-neutral-300
              hover:bg-cp-neutral-50
            ;

            &.cp-button--loading {
              .cp-loading-spinner {
                @apply
                border-cp-neutral-200
                ;
              }
            }

            .cp-button__content {
              @apply
              text-cp-neutral-500
              ;
            }

            &:active {
              @apply
              border-cp-neutral-700
              bg-white
              ;

              .cp-button__content {
                @apply
                text-cp-neutral-700
                ;
              }
            }

            &.cp-button--disabled {
              @apply
                border-cp-neutral-100
                bg-white
              ;

              .cp-button__content {
                @apply
                  text-cp-neutral-300
                ;
              }
            }
          }
        }
      }
    }

    &-text {
      &.cp-button {
        &--color {
          &-main {
            @apply
              bg-transparent
              hover:bg-cp-main-50
            ;

            .cp-button__content {
              @apply
                text-main
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-300
              ;
            }
          }

          &-accent {
            @apply
              bg-transparent
              hover:bg-cp-accent-50
            ;

            .cp-button__content {
              @apply
              text-cp-accent-200
              ;
            }

            &:active {
              .cp-button__content {
                @apply
                text-cp-accent-300
                ;
              }
            }

            &.cp-button--disabled {
              .cp-button__content {
                @apply
                  text-cp-neutral-300
                ;
              }
            }
          }

          &-neutral {
            @apply
              bg-transparent
              hover:bg-cp-neutral-50
            ;

            .cp-button__content {
              @apply
                text-cp-neutral-500
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-500
              ;
            }
          }

          &-red {
            @apply
              bg-transparent
              hover:bg-red-50
            ;

            .cp-button__content {
              @apply
                text-cp-base-red
              ;
            }

            &.cp-button--disabled {
              @apply
                bg-cp-neutral-500
              ;
            }
          }
        }
      }
    }
  }

  &--size {
    @apply
      text-sm
    ;

    &-sm {
      @apply
        py-2
        px-4
      ;

      &.cp-button--square,
      &.cp-button--rounded {
        @apply
          w-10
          h-10
        ;
      }
    }
  }
}
</style>

export enum ECP_EZE_PRODUCT_TABLE_COLUMNS {
  Description = 'Description',
  Value = 'Value',
  CustomValue = 'Custom Value',
  LastUpdate = 'Last Update',
  Action = 'Action'
}

export interface ICPEzeProductTableRow {
  [ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: string
  [ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: string | number
  [ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: string | number
  [ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: string
  [ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: {
    id: number
    isAttr: boolean
  } | null
}

export interface ICPEzeProductTableProps {
  data: ICPEzeProductTableRow[]
  loading?: boolean
}
